import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  addQuestionData,
  deleteQuestion,
  deleteQuestionFailure,
  deleteQuestionSuccess,
  getQuestionList,
  getQuestionListFailure,
  getQuestionListSuccess,
  getSingleQuestion,
  getSingleQuestionFailure,
  getSingleQuestionSuccess,
} from "./QuestionSlice.js";

function* onGetQuestion({
  payload: { page = 1, limit, type = "", status = "" },
}) {
  try {
    const url = `/question/data?limit=${limit}&pageNo=${page}${
      type ? "&type=" + type : ""
    }${status ? "&status=" + status : ""}`;
    const response = yield call(get, url);
    yield put(getQuestionListSuccess(response));
  } catch (error) {
    const message = error?.response?.data?.message || "Get question failed";
    toaster("error", message);
    yield put(getQuestionListFailure({ message }));
  }
}

function* onAddQuestion({
  payload: {
    data,
    options: { setSubmitting, resetForm, history, id },
  },
}) {
  try {
    const url = id ? `/question/${id}` : `/question`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster("success", `Question ${id ? "updated" : "added"} successfully`);
      setSubmitting(false);
      resetForm();
      history.push("/admin/questions");
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} question Failed`;
    toaster("error", message);
    setSubmitting(false);
  }
}

function* onGetSingleQuestion({ payload: { id } }) {
  try {
    const url = `/question/${id}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getSingleQuestionSuccess(response?.question));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single question failed";
    toaster("error", message);
    yield put(getSingleQuestionFailure({ message }));
  }
}

function* onDeleteQuestion({
  payload: { id, setSelectedUser, setCurrentPage, setHandleList },
}) {
  try {
    const url = `/question/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteQuestionSuccess(response));
      toaster("success", "Question delete successfully");
      // setSelectedUser(null);
      setCurrentPage(1);
      setHandleList(10);
      yield put(getQuestionList({ page: 1, limit: 10 }));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Delete question failed";
    toaster("error", message);
    yield put(deleteQuestionFailure({ message }));
  }
}

export function* questionSaga() {
  yield takeEvery(getQuestionList.type, onGetQuestion);
  yield takeEvery(addQuestionData.type, onAddQuestion);
  yield takeEvery(getSingleQuestion.type, onGetSingleQuestion);
  yield takeEvery(deleteQuestion.type, onDeleteQuestion);
}
