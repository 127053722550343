import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  intervieweeList: {},
  singleInterviewee: {},
  singleInterviewResult: {},
  viewOfferLetter: {},
  questionInfo: [],
  loading: true,
  error: "",
  adding: false,
};

const IntervieweeSlice = createSlice({
  name: "interviewee",
  initialState: initialState,
  reducers: {
    storeQuestion: (state, { payload: { name, value } }) => {
      state[name] = value;
    },

    // storeQuestionSuccess: (state, action) => {
    //   state.questionInfo = action.payload;
    //   state.loading = false;
    // },
    // storeQuestionFailure: (state, action) => {
    //   state.error = action.payload.message;
    //   state.loading = false;
    // },
    getIntervieweeList: (state, action) => {
      state.loading = true;
      state.adding = true;
    },
    getIntervieweeListSuccess: (state, action) => {
      state.intervieweeList = action.payload;
      state.loading = false;
      state.adding = false;
    },
    getIntervieweeListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
      state.loading = false;
    },
    getSingleInterviewee: (state, action) => {
      state.loading = true;
      state.adding = true;
    },
    getSingleIntervieweeSuccess: (state, action) => {
      state.singleInterviewee = action.payload;
      state.loading = false;
      state.adding = false;
    },
    getSingleIntervieweeFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
      state.adding = false;
    },
    getSingleInterviewResult: (state, action) => {
      state.loading = true;
      state.adding = true;
    },
    getSingleInterviewResultSuccess: (state, action) => {
      state.singleInterviewResult = action.payload;
      state.loading = false;
      state.adding = false;
    },
    getSingleInterviewResultFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
      state.adding = false;
    },
    getViewOfferLetter: (state, action) => {
      state.loading = true;
      state.adding = true;
    },
    getViewOfferLetterSuccess: (state, action) => {
      state.viewOfferLetter = action.payload;
      state.loading = false;
      state.adding = false;
    },
    getViewOfferLetterFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
      state.adding = false;
    },
    addInterviewResultData: (state, action) => {},
    addOfferLetterData: (state, action) => {},
  },
});

export const {
  getViewOfferLetter,
  getViewOfferLetterSuccess,
  getViewOfferLetterFailure,
  getIntervieweeList,
  getSingleInterviewResult,
  getSingleInterviewResultSuccess,
  getSingleInterviewResultFailure,
  getIntervieweeListSuccess,
  getIntervieweeListFailure,
  getSingleInterviewee,
  getSingleIntervieweeSuccess,
  getSingleIntervieweeFailure,
  addInterviewResultData,
  addOfferLetterData,
  storeQuestionSuccess,
  storeQuestionFailure,
  storeQuestion,
} = IntervieweeSlice.actions;

export default IntervieweeSlice.reducer;
