import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  levelList: [],
  loading: false,
  error: "",
  singleLevel: {},
  adding: false,
};

const levelSlice = createSlice({
  name: "level",
  initialState: initialState,
  reducers: {
    getLevelList: (state, action) => {
      state.loading = true;
    },
    getLevelListSuccess: (state, action) => {
      state.levelList = action.payload;
      state.loading = false;
    },
    getLevelListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addLevelData: (state, action) => {},
    getSingleLevel: (state, action) => {
      state.loading = true;
    },
    getSingleLevelSuccess: (state, action) => {
      state.singleLevel = action.payload;
      state.loading = false;
    },
    getSingleLevelFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteLevel: (state, action) => {
      state.adding = true;
    },
    deleteLevelSuccess: (state, action) => {
      state.adding = false;
    },
    deleteLevelFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getLevelList,
  getLevelListSuccess,
  getLevelListFailure,
  addLevelData,
  getSingleLevel,
  getSingleLevelSuccess,
  getSingleLevelFailure,
  deleteLevel,
  deleteLevelSuccess,
  deleteLevelFailure,
} = levelSlice.actions;

export default levelSlice.reducer;
