import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  leaveTypeList: {},
  loading: false,
  error: "",
  singleLeaveType: {},
  adding: false,
};

const LeaveTypeSlice = createSlice({
  name: "leaveType",
  initialState: initialState,
  reducers: {
    getLeaveTypeList: (state, action) => {
      state.loading = true;
    },
    getLeaveTypeListSuccess: (state, action) => {
      state.leaveTypeList = action.payload;
      state.loading = false;
    },
    getLeaveTypeListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addLeaveTypeData: (state, action) => {},
    getSingleLeaveType: (state, action) => {
      state.loading = true;
    },
    getSingleLeaveTypeSuccess: (state, action) => {
      state.singleLeaveType = action.payload;
      state.loading = false;
    },
    getSingleLeaveTypeFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteLeaveType: (state, action) => {
      state.adding = true;
    },
    deleteLeaveTypeSuccess: (state, action) => {
      state.adding = false;
    },
    deleteLeaveTypeFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getLeaveTypeList,
  getLeaveTypeListSuccess,
  getLeaveTypeListFailure,
  addLeaveTypeData,
  getSingleLeaveType,
  getSingleLeaveTypeSuccess,
  getSingleLeaveTypeFailure,
  deleteLeaveType,
  deleteLeaveTypeSuccess,
  deleteLeaveTypeFailure,
} = LeaveTypeSlice.actions;

export default LeaveTypeSlice.reducer;
