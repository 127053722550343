import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../helpers/api_helper";
import { toaster } from "../../helpers/custom/Toast";
import {
  addJobPostData,
  deleteJobPost,
  deleteJobPostFailure,
  deleteJobPostSuccess,
  getJobPostList,
  getJobPostListFailure,
  getJobPostListSuccess,
  getSingleJobPost,
  getSingleJobPostFailure,
  getSingleJobPostSuccess,
} from "./JobPostSlice";

function* onGetJobPost({
  payload: { page = 1, limit, status = "", department },
}) {
  try {
    const url = `/job-post/data/?limit=${limit}&pageNo=${page}&status=${status}${
      department ? `&department=${department}` : ""
    }`;
    const response = yield call(get, url);
    if (response) {
      yield put(getJobPostListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get Job Post Failed";
    yield put(getJobPostListFailure({ message }));
  }
}

function* onAddJobPost({
  payload: {
    data,
    options: { setSubmitting, resetForm, history, toggle, id },
  },
}) {
  try {
    const url = id ? `/job-post/${id}` : `/job-post`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster("success", `Job Post ${id ? "Updated" : "Added"} Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      // history.push("/admin/job-post");
      toggle ? toggle(true) : history.push("/admin/job-post");
    }
  } catch (error) {
    // const message =
    //   error?.response?.data?.message ||
    //   `${id ? "Update" : "Add"} Job Post Failed`;
    // toaster("error", message);
    // yield setSubmitting(false);
  }
}

function* onGetSingleJobPost({ payload: { id } }) {
  try {
    const url = `/job-post/${id}`;
    const response = yield call(get, url);
    // console.log(`response`, response);
    if (response) {
      yield put(getSingleJobPostSuccess(response?.jobPost));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single jobPost failed";
    toaster("error", message);
    yield put(getSingleJobPostFailure({ message }));
  }
}

function* onDeleteJobPost({
  payload: { id, setSelectedUser, setCurrentPage, setHandleList },
}) {
  try {
    const url = `/job-post/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteJobPostSuccess(response));
      toaster("success", "Job Post Deleted Successfully");
      setSelectedUser(null);
      setCurrentPage(1);
      setHandleList(10);
      yield put(getJobPostList({ page: 1, limit: 10 }));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Delete job post failed";
    toaster("error", message);
    yield put(deleteJobPostFailure({ message }));
  }
}

export function* jobPostSaga() {
  yield takeEvery(getJobPostList.type, onGetJobPost);
  yield takeEvery(addJobPostData.type, onAddJobPost);
  yield takeEvery(getSingleJobPost.type, onGetSingleJobPost);
  yield takeEvery(deleteJobPost.type, onDeleteJobPost);
}
