import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  leaveList: [],
  loading: false,
  error: "",
  adding: false,
};

const leaveSlice = createSlice({
  name: "leave",
  initialState: initialState,
  reducers: {
    getLeaveList: (state, action) => {
      state.loading = true;
    },
    getLeaveListSuccess: (state, action) => {
      state.leaveList = action.payload;
      state.loading = false;
    },
    getLeaveListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addLeaveData: (state, action) => {},
    applicationReview: (state, action) => {},

    deleteLeave: (state, action) => {
      state.adding = true;
    },
    deleteLeaveSuccess: (state, action) => {
      state.adding = false;
    },
    deleteLeaveFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getLeaveList,
  getLeaveListSuccess,
  getLeaveListFailure,
  addLeaveData,
  applicationReview,
  deleteLeave,
  deleteLeaveSuccess,
  deleteLeaveFailure,
} = leaveSlice.actions;

export default leaveSlice.reducer;
