import { call, put, takeEvery } from "redux-saga/effects";
import { del, get } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  deleteAdminPromotion,
  deleteAdminPromotionFailure,
  deleteAdminPromotionSuccess,
  getAdminPromotionList,
  getAdminPromotionListFailure,
  getAdminPromotionListSuccess,
  getCancelAdminPromotion,
  getCancelAdminPromotionFailure,
  getCancelAdminPromotionSuccess,
  getSingleAdminPromotion,
  getSingleAdminPromotionFailure,
  getSingleAdminPromotionSuccess,
} from "./PromotionSlice";

function* onGetPromotion({
  payload: {
    page = 0,
    limit = 0,
    employeeId = "",
    sbu = "",
    department = "",
    designation = "",
    year = "",
  },
}) {
  try {
    const url = `/promotion/data/?limit=${limit}&pageNo=${page}&employeeId=${employeeId}&concernCompany=${sbu}&department=${department}&designation=${designation}&year=${year}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getAdminPromotionListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get promotion failed";
    yield put(getAdminPromotionListFailure({ message }));
  }
}

function* onGetSinglePromotion({ payload: { id = "" } }) {
  try {
    const url = `/promotion/${id}`;
    const response = yield call(get, url);
    // console.log(`response`, response);
    if (response) {
      yield put(getSingleAdminPromotionSuccess(response?.promotion));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single promotion failed";
    toaster("error", message);
    yield put(getSingleAdminPromotionFailure({ message }));
  }
}

function* onGetCancelPromotion({
  payload: { id = "", employeeId = "", toggle },
}) {
  try {
    const url = `/promotion/cancel-promotion/?id=${id}&employeeId=${employeeId}`;
    const response = yield call(get, url);
    // console.log(`response`, response);
    if (response) {
      yield put(getCancelAdminPromotionSuccess(response));
      toaster("success", "Promotion cancel successfully");
      toggle(true);
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Cancel promotion failed";
    toaster("error", message);
    yield put(getCancelAdminPromotionFailure({ message }));
  }
}

function* onDeletePromotion({ payload: { id, successCallBack } }) {
  try {
    const url = `/promotion/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteAdminPromotionSuccess(response));
      toaster("success", "Promotion Deleted successfully");
      successCallBack();
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Delete promotion failed";
    toaster("error", message);
    yield put(deleteAdminPromotionFailure({ message }));
  }
}

export function* adminPromotionSaga() {
  yield takeEvery(getAdminPromotionList.type, onGetPromotion);
  yield takeEvery(getSingleAdminPromotion.type, onGetSinglePromotion);
  yield takeEvery(getCancelAdminPromotion.type, onGetCancelPromotion);
  yield takeEvery(deleteAdminPromotion.type, onDeletePromotion);
}
