import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../helpers/api_helper";
import { toaster } from "../../helpers/custom/Toast";
import {
  addConcernCompanyData,
  deleteConcernCompany,
  deleteConcernCompanyFailure,
  deleteConcernCompanySucces,
  getConcernCompanyList,
  getConcernCompanyListFailure,
  getConcernCompanyListSuccess,
  getSingleConcernCompany,
  getSingleConcernCompanyFailure,
  getSingleConcernCompanySuccess,
} from "./ConcernCompanySlice";

function* onGetConcernCompany({ payload: { page = 1, limit } }) {
  try {
    const url = `/concern/data/?limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getConcernCompanyListSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get concern company Failed";
    yield put(getConcernCompanyListFailure({ message }));
  }
}

function* onAddConcernCompany({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id ? `/concern/${id}` : `/concern`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster(
        "success",
        `Concern Company ${id ? "Updated" : "Added"} Successfully`
      );
      yield setSubmitting(false);
      yield resetForm();
      yield put(getConcernCompanyList({ page: 1, limit: 10 }));
      toggle();
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} Concern Company Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetSingleConcernCompany({ payload: { id } }) {
  try {
    const url = `/concern/${id}`;
    const response = yield call(get, url);
    // console.log(`response`, response);
    if (response) {
      yield put(getSingleConcernCompanySuccess(response?.concern));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single concern company failed";
    toaster("error", message);
    yield put(getSingleConcernCompanyFailure({ message }));
  }
}

function* onDeleteConcernCompany({
  payload: { id, setSelectedUser, setCurrentPage, setHandleList, toggle },
}) {
  try {
    const url = `/concern/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteConcernCompanySucces(response));
      toaster("success", "Concern Company Deleted successfully");
      setSelectedUser(null);
      setCurrentPage(1);
      setHandleList(10);
      toggle(true);
      // yield put(getConcernCompanyList({ page: 1, limit: 10 }));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Delete concern company failed";
    toaster("error", message);
    yield put(deleteConcernCompanyFailure({ message }));
  }
}

export function* concernCompanySaga() {
  yield takeEvery(getConcernCompanyList.type, onGetConcernCompany);
  yield takeEvery(addConcernCompanyData.type, onAddConcernCompany);
  yield takeEvery(getSingleConcernCompany.type, onGetSingleConcernCompany);
  yield takeEvery(deleteConcernCompany.type, onDeleteConcernCompany);
}
