import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  designationList: {},
  loading: false,
  error: "",
  singleDesignation: {},
  adding: false,
};

const designationSlice = createSlice({
  name: "designation",
  initialState: initialState,
  reducers: {
    getDesignationList: (state, action) => {
      state.loading = true;
    },
    getDesignationListSuccess: (state, action) => {
      state.designationList = action.payload;
      state.loading = false;
    },
    getDesignationListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addDesignationData: (state, action) => {},
    getSingleDesignation: (state, action) => {
      state.loading = true;
    },
    getSingleDesignationSuccess: (state, action) => {
      state.singleDesignation = action.payload;
      state.loading = false;
    },
    getSingleDesignationFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteDesignation: (state, action) => {
      state.adding = true;
    },
    deleteDesignationSuccess: (state, action) => {
      state.adding = false;
    },
    deleteDesignationFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getDesignationList,
  getDesignationListSuccess,
  getDesignationListFailure,
  addDesignationData,
  getSingleDesignation,
  getSingleDesignationSuccess,
  getSingleDesignationFailure,
  deleteDesignation,
  deleteDesignationSuccess,
  deleteDesignationFailure,
} = designationSlice.actions;

export default designationSlice.reducer;
