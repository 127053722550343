import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../helpers/api_helper";
import { toaster } from "../../helpers/custom/Toast";
import {
  addBankData,
  deleteBank,
  deleteBankFailure,
  deleteBankSuccess,
  getBankList,
  getBankListFailure,
  getBankListSuccess,
  getSingleBank,
  getSingleBankFailure,
  getSingleBankSuccess,
} from "./BankSlice";

function* onGetBank({ payload: { page = 1, limit } }) {
  try {
    const url = `/bank/data?limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getBankListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get Bank Failed";
    yield put(getBankListFailure({ message }));
  }
}

function* onAddBank({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id ? `/bank/${id}` : `/bank`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster("success", `Bank ${id ? "Updated" : "Added"} Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      yield put(getBankList({ page: 1, limit: 10 }));
      toggle();
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || `${id ? "Update" : "Add"} Bank Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetSingleBank({ payload: { id } }) {
  try {
    const url = `/bank/${id}`;
    const response = yield call(get, url);
    // console.log(`response`, response);
    if (response) {
      yield put(getSingleBankSuccess(response?.bank));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get single bank failed";
    toaster("error", message);
    yield put(getSingleBankFailure({ message }));
  }
}

function* onDeleteBank({
  payload: { id, setSelectedUser, setCurrentPage, setHandleList },
}) {
  try {
    const url = `/bank/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteBankSuccess(response));
      toaster("success", "Bank Deleted successfully");
      setSelectedUser(null);
      setCurrentPage(1);
      setHandleList(10);
      yield put(getBankList({ page: 1, limit: 10 }));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Delete bank failed";
    toaster("error", message);
    yield put(deleteBankFailure({ message }));
  }
}

export function* bankSaga() {
  yield takeEvery(getBankList.type, onGetBank);
  yield takeEvery(addBankData.type, onAddBank);
  yield takeEvery(getSingleBank.type, onGetSingleBank);
  yield takeEvery(deleteBank.type, onDeleteBank);
}
