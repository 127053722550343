import { call, put, takeEvery } from "redux-saga/effects";
import { get, patch, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  addSalaryIncrementData,
  getSalaryIncrementList,
  getSalaryIncrementListFailure,
  getSalaryIncrementListSuccess,
  getSingleSalaryIncrement,
  getSingleSalaryIncrementByIdAndYear,
  getSingleSalaryIncrementByIdAndYearFailure,
  getSingleSalaryIncrementByIdAndYearSuccess,
  getSingleSalaryIncrementFailure,
  getSingleSalaryIncrementSuccess,
} from "./salaryIncrementSlice";

function* onGetSalaryIncrement({
  payload: { page = 1, limit = 10, employeeId = "", year = "" },
}) {
  try {
    const url = `/employee-panel/increment/data/?limit=${limit}&pageNo=${page}&employeeId=${employeeId}&year=${year}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getSalaryIncrementListSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get salary salaryIncrement failed";
    yield put(getSalaryIncrementListFailure({ message }));
  }
}

function* onAddSalaryIncrement({
  payload: {
    data,
    options: {
      setSubmitting,
      resetForm,
      id,
      salaryIncrementList,
      toggle,
      setOpenConfirm,
    },
  },
}) {
  try {
    const url = id
      ? `/employee-panel/increment/${id}`
      : `/employee-panel/increment/`;
    const response = yield call(id ? patch : post, url, data);

    if (response) {
      // console.log(response);
      toaster("success", `Salary Increment${id ? " Update" : ""} Successfully`);
      salaryIncrementList();
      yield setSubmitting(false);
      yield resetForm();
      yield toggle(true);
      yield setOpenConfirm(false);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} SalaryIncrement Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetSingleSalaryIncrement({ payload: { id = "" } }) {
  try {
    const url = `/employee-panel/increment/${id}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getSingleSalaryIncrementSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      "Get single salary salaryIncrement failed";
    toaster("error", message);
    yield put(getSingleSalaryIncrementFailure({ message }));
  }
}

function* onGetSingleSalaryIncrementByIdAndYear({
  payload: { employeeId = "", year = "" },
}) {
  try {
    const url = `/employee-panel/increment/get-increment-by-employeeId-and-year/?employeeId=${employeeId}&year=${year}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getSingleSalaryIncrementByIdAndYearSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      "Get single salary salary Increment by employee id and year failed";
    toaster("error", message);
    yield put(getSingleSalaryIncrementByIdAndYearFailure({ message }));
  }
}

// function* onDeleteSalaryIncrement({ payload: { id, successCallBack } }) {
//   try {
//     const url = `/employee-panel/increment/${id}`;
//     const response = yield call(del, url);
//     if (response) {
//       yield put(deleteSalaryIncrementSuccess(response));
//       toaster("success", "SalaryIncrement Deleted successfully");
//       successCallBack();
//     }
//   } catch (error) {
//     const message =
//       error?.response?.data?.message || "Delete salary salaryIncrement failed";
//     toaster("error", message);
//     yield put(deleteSalaryIncrementFailure({ message }));
//   }
// }

export function* salaryIncrementSaga() {
  yield takeEvery(getSalaryIncrementList.type, onGetSalaryIncrement);
  yield takeEvery(addSalaryIncrementData.type, onAddSalaryIncrement);
  yield takeEvery(getSingleSalaryIncrement.type, onGetSingleSalaryIncrement);
  yield takeEvery(
    getSingleSalaryIncrementByIdAndYear.type,
    onGetSingleSalaryIncrementByIdAndYear
  );
  // yield takeEvery(deleteSalaryIncrement.type, onDeleteSalaryIncrement);
}
