import { call, put, takeEvery } from "redux-saga/effects";
import { get, patch, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  addAssessmentExamData,
  addOfferLetterData,
  getApplicantAssessmentList,
  getApplicantAssessmentListFailure,
  getApplicantAssessmentListSuccess,
  getApplicantChart,
  getApplicantChartFailure,
  getApplicantChartSuccess,
  getApplicantProfile,
  getApplicantProfileFailure,
  getApplicantProfileSuccess,
  getAppliedPosition,
  getAppliedPositionFailed,
  getAppliedPositionSuccess,
  getAssessmentView,
  getAssessmentViewFailure,
  getAssessmentViewSuccess,
  getOfferLetter,
  getOfferLetterFailure,
  getOfferLetterSuccess,
  getSingleOfferLetter,
  getSingleOfferLetterFailure,
  getSingleOfferLetterSuccess,
} from "./ApplicantSlice.js";

function* onGetAssessment({ payload: { page = 0, limit = 0, id = "" } }) {
  try {
    const url = `/applicant-panel/assessment/data/?id=${id}&limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    yield put(getApplicantAssessmentListSuccess(response));
  } catch (error) {
    const message = error?.response?.data?.message || "Get assessment failed";
    toaster("error", message);
    yield put(getApplicantAssessmentListFailure({ message }));
  }
}
function* onGetApplicantProfile({ payload: { id = "" } }) {
  try {
    const url = `/applicant-panel/profile/${id}`;
    const response = yield call(get, url);
    yield put(getApplicantProfileSuccess(response));
  } catch (error) {
    const message = error?.response?.data?.message || "Get profile failed";
    toaster("error", message);
    yield put(getApplicantProfileFailure({ message }));
  }
}
function* onGetSingleAssessment({ payload: { id = "" } }) {
  try {
    const url = `/applicant-panel/assessment/${id}`;
    const response = yield call(get, url);
    yield put(getAssessmentViewSuccess(response));
  } catch (error) {
    const message = error?.response?.data?.message || "Get assessment failed";
    toaster("error", message);
    yield put(getAssessmentViewFailure({ message }));
  }
}
function* onAddAssessmentExam({
  payload: {
    data,
    options: { setSubmitting, resetForm, id, history, setGetVideo },
  },
}) {
  try {
    const url = `/applicant-panel/assessment/${id}`;
    const response = yield call(patch, url, data);
    if (response) {
      toaster("success", `Assessment Exam Data Added Successfully`);
      yield setSubmitting(false);
      resetForm && resetForm();
      setGetVideo && setGetVideo({ take: false, data: {} });
      history.push("/applicant/dashboard");
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || `Assessment Exam added Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}
function* onGetOfferData() {
  try {
    const url = `applicant-panel/career/get-offer-letter`;
    const response = yield call(get, url);
    yield put(getOfferLetterSuccess(response));
  } catch (error) {
    const message = error?.response?.data?.message || "Get offer data failed";
    toaster("error", message);
    yield put(getOfferLetterFailure({ message }));
  }
}
function* onAddOfferData({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, status },
  },
}) {
  try {
    const url = `/applicant-panel/career/offer-letter-update`;
    const response = yield call(post, url, data);
    if (response) {
      if (status === "Agree") {
        toaster("success", `You Agreed Successfully`);
      } else if (status === "Disagree") {
        toaster("success", `Your Disagreed Successfully`);
      } else {
        toaster("success", `Your Negotiated Successfully`);
      }
      yield setSubmitting(false);
      resetForm && resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || `Offer letter data added Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}
function* onGetSingleOfferLetter({ payload: { id = "" } }) {
  try {
    const url = `/applicant-panel/career/show-offer-letter/${id}`;
    const response = yield call(get, url);
    yield put(getSingleOfferLetterSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single offer letter failed";
    toaster("error", message);
    yield put(getSingleOfferLetterFailure({ message }));
  }
}

function* onGetApplicantChart() {
  try {
    const url = `/applicant-panel/career/get-assessment-progress`;
    const response = yield call(get, url);
    yield put(getApplicantChartSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single offer letter failed";
    toaster("error", message);
    yield put(getApplicantChartFailure({ message }));
  }
}
function* onGetAppliedPositionData({ payload: { page = 0, limit = 0 } }) {
  try {
    const url = `/applicant-panel/career/data/?&limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    yield put(getAppliedPositionSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get applied position failed";
    toaster("error", message);
    yield put(getAppliedPositionFailed({ message }));
  }
}

export function* applicantSaga() {
  yield takeEvery(getAppliedPosition.type, onGetAppliedPositionData);
  yield takeEvery(getApplicantAssessmentList.type, onGetAssessment);
  yield takeEvery(getApplicantProfile.type, onGetApplicantProfile);
  yield takeEvery(getApplicantChart.type, onGetApplicantChart);
  yield takeEvery(getAssessmentView.type, onGetSingleAssessment);
  yield takeEvery(addAssessmentExamData.type, onAddAssessmentExam);
  yield takeEvery(getOfferLetter.type, onGetOfferData);
  yield takeEvery(addOfferLetterData.type, onAddOfferData);
  yield takeEvery(getSingleOfferLetter.type, onGetSingleOfferLetter);
}
