import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  addIncrementData,
  deleteIncrement,
  deleteIncrementFailure,
  deleteIncrementSuccess,
  getCancelSalary,
  getCancelSalaryFailure,
  getCancelSalarySuccess,
  getIncrementById,
  getIncrementByIdFailure,
  getIncrementByIdSuccess,
  getIncrementList,
  getIncrementListFailure,
  getIncrementListSuccess,
  getPrevSalary,
  getPrevSalaryFailure,
  getPrevSalarySuccess,
  getSingleIncrement,
  getSingleIncrementFailure,
  getSingleIncrementSuccess,
} from "./adminIncrementSlice";

function* onGetIncrement({
  payload: {
    page = 0,
    limit = 0,
    employeeId = "",
    sbu = "",
    department = "",
    designation = "",
    year = "",
  },
}) {
  try {
    const url = `/increment/data/?limit=${limit}&pageNo=${page}&employeeId=${employeeId}&concernCompany=${sbu}&department=${department}&designation=${designation}&year=${year}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getIncrementListSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get salary increment failed";
    yield put(getIncrementListFailure({ message }));
  }
}

function* onAddIncrement({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id ? `/increment/update-increment/${id}` : `/increment/`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster("success", `Increment ${id ? "Updated" : "Added"} Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} Increment Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetIncrementById({ payload: { id = "", year = "" } }) {
  try {
    const url = `/increment/get-increment-by-employeeId-and-year/?employeeId=${id}&year=${year}`;
    const response = yield call(get, url);
    // console.log(`response`, response);
    if (response) {
      yield put(getIncrementByIdSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Increment by Id failed";
    toaster("error", message);
    yield put(getIncrementByIdFailure({ message }));
  }
}

function* onGetSingleIncrement({ payload: { id } }) {
  try {
    const url = `/increment/${id}`;
    const response = yield call(get, url);
    // console.log(`response`, response);
    if (response) {
      yield put(getSingleIncrementSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single salary increment failed";
    toaster("error", message);
    yield put(getSingleIncrementFailure({ message }));
  }
}

function* onDeleteIncrement({ payload: { id, successCallBack } }) {
  try {
    const url = `/increment/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteIncrementSuccess(response));
      toaster("success", "Increment Deleted successfully");
      successCallBack();
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Delete salary increment failed";
    toaster("error", message);
    yield put(deleteIncrementFailure({ message }));
  }
}

// get prev salary
function* onGetPrevSalary({ payload: { id = "" } }) {
  try {
    const url = `/increment/get-previous-salary-by-employee/${id}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getPrevSalarySuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get previous salary salary failed";
    toaster("error", message);
    yield put(getPrevSalaryFailure({ message }));
  }
}

// cancel salary
function* onGetCancelSalary({
  payload: { id = "", employeeId = "", isRemove },
}) {
  try {
    const url = `/increment/cancel-increment?id=${id}&employeeId=${employeeId}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getCancelSalarySuccess(response));
      yield isRemove(true);
    }
  } catch (error) {
    console.log(error);
    const message =
      error?.response?.data?.message || "Get cancel salary salary failed";
    toaster("error", message);
    yield put(getCancelSalaryFailure({ message }));
  }
}

export function* adminIncrementSaga() {
  yield takeEvery(getIncrementList.type, onGetIncrement);
  yield takeEvery(addIncrementData.type, onAddIncrement);
  yield takeEvery(getIncrementById.type, onGetIncrementById);
  yield takeEvery(getSingleIncrement.type, onGetSingleIncrement);
  yield takeEvery(getPrevSalary.type, onGetPrevSalary);
  yield takeEvery(getCancelSalary.type, onGetCancelSalary);
  yield takeEvery(deleteIncrement.type, onDeleteIncrement);
}
