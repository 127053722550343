import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  addTaxData,
  deleteTax,
  deleteTaxFailure,
  deleteTaxSuccess,
  getSingleTax,
  getSingleTaxFailure,
  getSingleTaxReport,
  getSingleTaxReportFailure,
  getSingleTaxReportSuccess,
  getSingleTaxSuccess,
  getTaxList,
  getTaxListFailure,
  getTaxListSuccess,
  getTaxReport,
  getTaxReportFailure,
  getTaxReportSuccess,
} from "./TaxSlice";

function* onGetTax({ payload: { page = 0, limit = 0 } }) {
  try {
    const url = `/income-slab/?limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getTaxListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get Tax failed";
    yield put(getTaxListFailure({ message }));
  }
}

function* onAddTax({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id ? `/income-slab/${id}` : `/income-slab/`;
    const response = yield call(id ? patch : post, url, data);

    if (response) {
      toaster("success", `Tax ${id ? "Updated" : "Added"} Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
      // history.push('/admin/performance-grade-chart');
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} Performance Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetSingleTax({ payload: { id = "" } }) {
  try {
    const url = `/income-slab/${id}`;
    const response = yield call(get, url);
    // console.log(`response`, response);
    if (response) {
      yield put(getSingleTaxSuccess(response?.promotion));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get single Tax failed";
    toaster("error", message);
    yield put(getSingleTaxFailure({ message }));
  }
}

function* onDeleteTax({ payload: { id, successCallBack } }) {
  try {
    const url = `/income-slab/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteTaxSuccess(response));
      toaster("success", "Tax Deleted successfully");
      successCallBack();
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Delete Tax failed";
    toaster("error", message);
    yield put(deleteTaxFailure({ message }));
  }
}

function* onGetTaxReport({
  payload: {
    page = 0,
    limit = 0,
    sbu = "",
    department = "",
    designation = "",
    formDate = "",
    toDate = "",
    year,
  },
}) {
  try {
    const url = `/salary/get-tax-report/?concernCompany=${sbu}&department=${department}&designation=${designation}&limit=${limit}&pageNo=${page}&fromDate=${formDate}&toDate=${toDate}&year=${year}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getTaxReportSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get Tax failed";
    yield put(getTaxReportFailure({ message }));
  }
}

function* onGetSingleTaxReport({
  payload: { employeeId = "", formDate = "", toDate = "", year },
}) {
  try {
    const url = `/salary/get-tax-details/?employeeId=${employeeId}&fromDate=${formDate}&toDate=${toDate}&year=${year}`;
    const response = yield call(get, url);
    console.log({ response });
    if (response) {
      yield put(getSingleTaxReportSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get Tax failed";
    yield put(getSingleTaxReportFailure({ message }));
  }
}

export function* taxSaga() {
  yield takeEvery(getTaxList.type, onGetTax);
  yield takeEvery(addTaxData.type, onAddTax);
  yield takeEvery(getSingleTax.type, onGetSingleTax);
  yield takeEvery(deleteTax.type, onDeleteTax);
  yield takeEvery(getTaxReport.type, onGetTaxReport);
  yield takeEvery(getSingleTaxReport.type, onGetSingleTaxReport);
}
