import { call, put, takeEvery } from "redux-saga/effects";
import { get } from "../../../helpers/api_helper";
import {
  getAttendanceLog,
  getAttendanceLogFailure,
  getAttendanceLogSuccess,
  getAttendanceSummary,
  getAttendanceSummaryFailure,
  getAttendanceSummarySuccess,
  getDailyAttendanceReportList,
  getDailyAttendanceReportListFailure,
  getDailyAttendanceReportListSuccess,
} from "./attendanceSlice";

function* onGetDailyAttendanceReport({
  payload: { date, status, sbu, department, designation },
}) {
  try {
    const url = `/attendance/daily-attendance-report?employeeId=&todayDate=${date}&status=${status}&concernCompany=${sbu}&designation=${designation}&department=${department}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getDailyAttendanceReportListSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get daily attendance report failed";
    yield put(getDailyAttendanceReportListFailure({ message }));
  }
}

function* onGetAttendanceLog({
  payload: { fromDate, toDate, status, sbu, department, designation },
}) {
  try {
    const url = `/attendance/attendance-log-report?employeeId=&fromDate=${fromDate}&toDate=${toDate}&concernCompany=${sbu}&designation=${designation}&department=${department}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getAttendanceLogSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get attendance log failed";
    yield put(getAttendanceLogFailure({ message }));
  }
}

function* onGetAttendanceSummary({
  payload: { fromDate, toDate, sbu, department, designation },
}) {
  try {
    const url = `/attendance/date-to-date-attendance-summary?employeeId=&fromDate=${fromDate}&toDate=${toDate}&concernCompany=${sbu}&designation=${designation}&department=${department}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getAttendanceSummarySuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get attendance summary failed";
    yield put(getAttendanceSummaryFailure({ message }));
  }
}

// function* onAddAttendance({
//   payload: {
//     data,
//     options: { setSubmitting, resetForm, toggle, dayOff },
//   },
// }) {
//   try {
//     const url = dayOff
//       ? `/employee-panel/attendance/day-off`
//       : `/employee-panel/attendance/attendance-create`;
//     const response = yield call(dayOff ? patch : post, url, data);

//     if (response) {
//       toaster(
//         "success",
//         `Attendance ${dayOff ? "Day Off" : "Added"} Successfully`
//       );
//       yield setSubmitting(false);
//       yield resetForm();
//       toggle(true);
//     }
//   } catch (error) {
//     const message =
//       error?.response?.data?.message ||
//       `${dayOff ? "Day Off" : "Add"} Attendance Failed`;
//     toaster("error", message);
//     yield setSubmitting(false);
//   }
// }

// function* onGetDailyAttendance() {
//   try {
//     const url = `/employee-panel/attendance/chcek-daily-attandance`;
//     const response = yield call(get, url);
//     if (response) {
//       yield put(getDailyAttendanceSuccess(response?.attendance));
//     }
//   } catch (error) {
//     const message =
//       error?.response?.data?.message || "Get single salary attendance failed";
//     toaster("error", message);
//     yield put(getDailyAttendanceFailure({ message }));
//   }
// }

// function* onDeleteAttendance({ payload: { id, successCallBack } }) {
//   try {
//     const url = `/employee-panel/leave-application/${id}`;
//     const response = yield call(del, url);
//     if (response) {
//       yield put(deleteAttendanceSuccess(response));
//       toaster("success", "Attendance Deleted successfully");
//       successCallBack();
//     }
//   } catch (error) {
//     const message =
//       error?.response?.data?.message || "Delete salary attendance failed";
//     toaster("error", message);
//     yield put(deleteAttendanceFailure({ message }));
//   }
// }

export function* dailyAttendanceReportSaga() {
  yield takeEvery(
    getDailyAttendanceReportList.type,
    onGetDailyAttendanceReport
  );
  yield takeEvery(getAttendanceLog.type, onGetAttendanceLog);
  yield takeEvery(getAttendanceSummary.type, onGetAttendanceSummary);
  //   yield takeEvery(addAttendanceData.type, onAddAttendance);
  //   yield takeEvery(getDailyAttendance.type, onGetDailyAttendance);
  //   yield takeEvery(deleteAttendance.type, onDeleteAttendance);
}
