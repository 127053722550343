import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dailyAttendanceReportList: {},
  dailyAttendanceLogList: {},
  attendanceSummaryList: {},
  loading: false,
  error: "",
  adding: false,
};

const dailyAttendanceReportSlice = createSlice({
  name: "dailyAttendanceReport",
  initialState: initialState,
  reducers: {
    getDailyAttendanceReportList: (state, action) => {
      state.loading = true;
    },
    getDailyAttendanceReportListSuccess: (state, action) => {
      state.dailyAttendanceReportList = action.payload;
      state.loading = false;
    },
    getDailyAttendanceReportListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    getAttendanceLog: (state, action) => {
      state.loading = true;
    },
    getAttendanceLogSuccess: (state, action) => {
      state.dailyAttendanceLogList = action.payload;
      state.loading = false;
    },
    getAttendanceLogFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    getAttendanceSummary: (state, action) => {
      state.loading = true;
    },
    getAttendanceSummarySuccess: (state, action) => {
      state.attendanceSummaryList = action.payload;
      state.loading = false;
    },
    getAttendanceSummaryFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addDailyAttendanceReportData: (state, action) => {},
    getDailyDailyAttendanceReport: (state, action) => {
      state.loading = true;
    },
    getDailyDailyAttendanceReportSuccess: (state, action) => {
      state.checkDailyAttendanceReport = action.payload;
      state.loading = false;
    },
    getDailyDailyAttendanceReportFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteDailyAttendanceReport: (state, action) => {
      state.adding = true;
    },
    deleteDailyAttendanceReportSuccess: (state, action) => {
      state.adding = false;
    },
    deleteDailyAttendanceReportFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getDailyAttendanceReportList,
  getDailyAttendanceReportListSuccess,
  getDailyAttendanceReportListFailure,
  getAttendanceLog,
  getAttendanceLogSuccess,
  getAttendanceLogFailure,
  getAttendanceSummary,
  getAttendanceSummarySuccess,
  getAttendanceSummaryFailure,
  addDailyAttendanceReportData,
  getDailyDailyAttendanceReport,
  getDailyDailyAttendanceReportSuccess,
  getDailyDailyAttendanceReportFailure,
  deleteDailyAttendanceReport,
  deleteDailyAttendanceReportSuccess,
  deleteDailyAttendanceReportFailure,
} = dailyAttendanceReportSlice.actions;

export default dailyAttendanceReportSlice.reducer;
