import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  holidaysList: {},
  monthlyHolidays: [],
  calendarLoading: true,
  loading: false,
  error: "",
  singleHolidays: {},
  adding: false,
};

const HolidaysSlice = createSlice({
  name: "holidays",
  initialState: initialState,
  reducers: {
    storeHolidays: (state, { payload: { name, data } }) => {
      state[name] = data;
    },
    getHolidaysList: (state, action) => {
      state.loading = true;
    },
    getHolidaysListSuccess: (state, action) => {
      state.holidaysList = action.payload;
      state.loading = false;
    },
    getHolidaysListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    getMonthlyHolidays: (state, action) => {
      state.calendarLoading = true;
    },
    getMonthlyHolidaysSuccess: (
      state,
      { payload: { leaveApplications = [], holidays = [] } }
    ) => {
      const data = [];
      if (leaveApplications?.length) {
        leaveApplications?.forEach((el) => {
          let obj = {};
          const date = el?.applicationStartDate;
          obj.startDate = date;
          obj.endDate = el?.applicationEndDate;
          obj.totalDays = el?.approvedDays;
          obj.colorCode = el?.leaveType?.colorCode;
          obj.type = el?.leaveType?.fullName;
          obj._id = el?._id;
          data.push(obj);
        });
      }
      state.monthlyHolidays = [...holidays, ...data];
      state.calendarLoading = false;
    },
    getMonthlyHolidaysFailure: (state, action) => {
      state.error = action.payload.message;
      state.calendarLoading = false;
    },
    addHolidaysData: (state, action) => {},
    getSingleHolidays: (state, action) => {
      state.loading = true;
    },
    getSingleHolidaysSuccess: (state, action) => {
      state.singleHolidays = action.payload;
      state.loading = false;
    },
    getSingleHolidaysFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteHolidays: (state, action) => {
      state.adding = true;
    },
    deleteHolidaysSuccess: (state, action) => {
      state.adding = false;
    },
    deleteHolidaysFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getHolidaysList,
  getHolidaysListSuccess,
  getHolidaysListFailure,
  getMonthlyHolidays,
  getMonthlyHolidaysSuccess,
  getMonthlyHolidaysFailure,
  addHolidaysData,
  getSingleHolidays,
  getSingleHolidaysSuccess,
  getSingleHolidaysFailure,
  deleteHolidays,
  deleteHolidaysSuccess,
  deleteHolidaysFailure,
  storeHolidays,
} = HolidaysSlice.actions;

export default HolidaysSlice.reducer;
