import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  addAttendanceData,
  deleteAttendance,
  deleteAttendanceFailure,
  deleteAttendanceSuccess,
  getAttendanceList,
  getAttendanceListFailure,
  getAttendanceListSuccess,
  getDailyAttendance,
  getDailyAttendanceFailure,
  getDailyAttendanceSuccess,
} from "./attendanceSlice";

function* onGetAttendance({
  payload: { page = 1, limit = 10, leaveType = "", status = "Pending" },
}) {
  try {
    const url = `/employee-panel/leave-application/data/?limit=${limit}&pageNo=${page}&leaveType=${leaveType}&status=${status}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getAttendanceListSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get salary attendance failed";
    yield put(getAttendanceListFailure({ message }));
  }
}

function* onAddAttendance({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, dayOff, handleSubmitting },
  },
}) {
  try {
    const url = dayOff
      ? `/employee-panel/attendance/day-off`
      : `/employee-panel/attendance/attendance-create`;
    const response = yield call(dayOff ? patch : post, url, data);

    if (response) {
      toaster(
        "success",
        `Attendance ${dayOff ? "Day Off" : "Added"} Successfully`
      );
      handleSubmitting(true);
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${dayOff ? "Day Off" : "Add"} Attendance Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetDailyAttendance() {
  try {
    const url = `/employee-panel/attendance/chcek-daily-attandance`;
    const response = yield call(get, url);
    if (response) {
      yield put(getDailyAttendanceSuccess(response?.attendance));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single salary attendance failed";
    toaster("error", message);
    yield put(getDailyAttendanceFailure({ message }));
  }
}

function* onDeleteAttendance({ payload: { id, successCallBack } }) {
  try {
    const url = `/employee-panel/leave-application/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteAttendanceSuccess(response));
      toaster("success", "Attendance Deleted successfully");
      successCallBack();
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Delete salary attendance failed";
    toaster("error", message);
    yield put(deleteAttendanceFailure({ message }));
  }
}

export function* attendanceSaga() {
  yield takeEvery(getAttendanceList.type, onGetAttendance);
  yield takeEvery(addAttendanceData.type, onAddAttendance);
  yield takeEvery(getDailyAttendance.type, onGetDailyAttendance);
  yield takeEvery(deleteAttendance.type, onDeleteAttendance);
}
