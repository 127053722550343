import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  careerList: {},
  career: [],
  careerJobPostList: {},
  cvScreeningList: {},
  assignedAssessmentList: {},
  loading: false,
  error: "",
  singleCareer: {},
  adding: false,
  cvScreeningLoading: false,
};

const CareerSlice = createSlice({
  name: "career",
  initialState: initialState,
  reducers: {
    storeCareerData: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
    getCareerList: (state, action) => {
      state.loading = true;
    },
    getCareerListSuccess: (state, action) => {
      state.careerList = action.payload;
      state.career = action.payload.careers;
      state.loading = false;
    },
    getCareerListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    getCareerJobPost: (state, action) => {
      state.loading = true;
    },
    getCareerJobPostSuccess: (state, action) => {
      state.careerJobPostList = action.payload;
      state.loading = false;
    },
    getCareerJobPostFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addCareerData: (state, action) => {},
    addCvScreeningData: (state, action) => {
      state.cvScreeningLoading = true;
    },
    addCvScreeningDataSuccess: (state, action) => {
      state.cvScreeningLoading = false;
    },
    addCvScreeningDataFail: (state, action) => {
      state.cvScreeningLoading = false;
    },
    assignInterviewData: (state, action) => {},
    assignAssessmentData: (state, action) => {},
    getCvScreeningData: (state, action) => {
      state.cvScreeningList = action.payload;
      state.loading = false;
    },
    getSingleCareer: (state, action) => {
      state.loading = true;
    },
    getSingleCareerSuccess: (state, action) => {
      state.singleCareer = action.payload;
      state.loading = false;
    },
    getSingleCareerFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },

    getAssignedAssessment: (state, action) => {
      state.loading = true;
    },
    getAssignedAssessmentSuccess: (state, action) => {
      state.assignedAssessmentList = action.payload;
      state.loading = false;
    },
    getAssignedAssessmentFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },

    deleteCareer: (state, action) => {
      state.adding = true;
    },
    deleteCareerSuccess: (state, action) => {
      state.adding = false;
    },
    deleteCareerFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
    deleteApplicantInterview: (state, action) => {
      state.adding = true;
    },
    deleteApplicantInterviewSuccess: (state, action) => {
      state.adding = false;
    },
    deleteApplicantInterviewFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
    deleteApplicantAssessment: (state, action) => {
      state.adding = true;
    },
    deleteApplicantAssessmentSuccess: (state, action) => {
      state.adding = false;
    },
    deleteApplicantAssessmentFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getAssignedAssessment,
  getAssignedAssessmentSuccess,
  getAssignedAssessmentFailure,
  deleteApplicantAssessment,
  deleteApplicantAssessmentSuccess,
  deleteApplicantAssessmentFailure,
  deleteApplicantInterview,
  deleteApplicantInterviewSuccess,
  deleteApplicantInterviewFailure,
  getCareerList,
  getCareerListSuccess,
  getCareerListFailure,
  getCareerJobPost,
  getCareerJobPostFailure,
  getCareerJobPostSuccess,
  addCareerData,
  getSingleCareer,
  getSingleCareerSuccess,
  getSingleCareerFailure,
  deleteCareer,
  deleteCareerSuccess,
  deleteCareerFailure,
  storeCvVaultData,
  storeCareerData,
  addCvScreeningData,
  getCvScreeningData,
  addCvScreeningDataSuccess,
  addCvScreeningDataFail,
  assignInterviewData,
  assignAssessmentData,
} = CareerSlice.actions;

export default CareerSlice.reducer;
