import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  kpiSelectionList: [],
  kpiSelectionSelectedList: [],
  kpiTargetSelectedList: [],
  kpiAchievedList: [],
  PerformanceRatingList: [],
  singlePerformance: {},
  kpiAchievedData: {},
  loading: false,
  error: "",
  singleKpiSelection: {},
  singleEmploye: {},
  adding: false,
};

const kpiSelectionSlice = createSlice({
  name: "kpiSelection",
  initialState: initialState,
  reducers: {
    resetPerformance: (state, action) => {
      state.kpiSelectionList = [];
      state.kpiSelectionSelectedList = [];
      state.kpiTargetSelectedList = [];
      state.kpiAchievedList = [];
      state.PerformanceRatingList = [];
      state.singlePerformance = {};
      state.kpiAchievedData = {};
      state.loading = false;
      state.error = "";
      state.singleKpiSelection = {};
      state.singleEmploye = {};
      state.adding = false;
    },
    storeKpiSelection: (state, { payload: { name, data } }) => {
      state[name] = data;
    },
    getKpiSelectionList: (state, action) => {
      state.loading = true;
    },
    getKpiSelectionListSuccess: (state, { payload }) => {
      state.kpiSelectionList = payload?.kpis;
      state.kpiSelectionSelectedList = payload?.selectedKpis;
      state.loading = false;
    },
    getKpiSelectionListFailure: (state, action) => {
      state.error = action.payload.message;
      state.kpiSelectionList = [];
      state.loading = false;
    },
    addKpiSelectionData: (state, action) => {
      state.adding = true;
    },
    addKpiTargetData: (state, action) => {
      state.adding = true;
    },
    getKpiSelectedSelectionList: (state, action) => {
      state.loading = true;
    },
    getKpiSelectedSelectionListSuccess: (state, action) => {
      state.kpiSelectionSelectedList = action.payload;
      state.loading = false;
    },
    getKpiSelectedSelectionListFailure: (state, action) => {
      state.error = action.payload.message;
      // state.kpiSelectionSelectedList = [];
      state.loading = false;
    },

    getKpiSelectedTargetList: (state, action) => {
      state.loading = true;
    },
    getKpiSelectedTargetListSuccess: (state, action) => {
      state.kpiTargetSelectedList = action.payload;
      state.loading = false;
    },
    getKpiSelectedTargetListFailure: (state, action) => {
      state.error = action.payload.message;
      state.kpiTargetSelectedList = [];
      state.loading = false;
    },

    addKpiAchievedData: (state, action) => {
      state.adding = true;
    },
    getKpiAchievedList: (state, action) => {
      state.loading = true;
    },
    getKpiAchievedListSuccess: (state, { payload }) => {
      state.kpiAchievedList = payload?.achievedList;
      state.kpiAchievedData = payload?.achievedData;
      state.loading = false;
    },
    getKpiAchievedListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },

    addPerformanceRatingData: (state, action) => {
      state.adding = true;
    },
    getPerformanceRatingList: (state, action) => {
      state.loading = true;
    },
    getPerformanceRatingListSuccess: (state, { payload }) => {
      state.PerformanceRatingList = payload;
      state.loading = false;
    },
    getPerformanceRatingListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },

    getSingleKpiSelection: (state, action) => {
      state.loading = true;
    },
    getSingleKpiSelectionSuccess: (state, action) => {
      state.singleKpiSelection = action.payload;
      state.loading = false;
    },
    getSingleKpiSelectionFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },

    getSinglePerformance: (state, action) => {
      state.loading = true;
    },
    getSinglePerformanceSuccess: (state, action) => {
      state.singlePerformance = action.payload;
      state.loading = false;
    },
    getSinglePerformanceFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },

    deleteKpiSelection: (state, action) => {
      state.adding = true;
    },
    deleteKpiSelectionSuccess: (state, action) => {
      state.adding = false;
    },
    deleteKpiSelectionFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },

    deletePerformanceRatting: (state, action) => {
      state.adding = true;
    },
    deletePerformanceRattingSuccess: (state, action) => {
      state.adding = false;
    },
    deletePerformanceRattingFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  storeKpiSelection,
  getKpiSelectionList,
  getKpiSelectionListSuccess,
  getKpiSelectionListFailure,
  addKpiSelectionData,
  addKpiTargetData,
  getKpiSelectedSelectionList,
  getKpiSelectedSelectionListSuccess,
  getKpiSelectedSelectionListFailure,
  getKpiSelectedTargetList,
  getKpiSelectedTargetListSuccess,
  getKpiSelectedTargetListFailure,
  addKpiAchievedData,
  getKpiAchievedList,
  getKpiAchievedListSuccess,
  getKpiAchievedListFailure,
  addPerformanceRatingData,
  getPerformanceRatingList,
  getPerformanceRatingListSuccess,
  getPerformanceRatingListFailure,
  getSingleKpiSelection,
  getSingleKpiSelectionSuccess,
  getSingleKpiSelectionFailure,
  getSinglePerformance,
  getSinglePerformanceSuccess,
  getSinglePerformanceFailure,
  deletePerformanceRatting,
  deletePerformanceRattingSuccess,
  deletePerformanceRattingFailure,
  deleteKpiSelection,
  deleteKpiSelectionSuccess,
  deleteKpiSelectionFailure,
  resetPerformance,
} = kpiSelectionSlice.actions;

export default kpiSelectionSlice.reducer;
