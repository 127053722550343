import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  addIncrementData,
  deleteIncrement,
  deleteIncrementFailure,
  deleteIncrementSuccess,
  getIncrementList,
  getIncrementListFailure,
  getIncrementListSuccess,
  getSingleEmploye,
  getSingleEmployeFailure,
  getSingleEmployeSuccess,
  getSingleIncrement,
  getSingleIncrementFailure,
  getSingleIncrementSuccess,
} from "./IncrementSlice";

function* onGetIncrement({
  payload: { page = 0, limit = 0, searchKeyword = "" },
}) {
  try {
    const url = `/employee-panel/profile/get-subordinate-employee/?limit=${limit}&pageNo=${page}&searchKeyword=${searchKeyword}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getIncrementListSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get salary increment failed";
    yield put(getIncrementListFailure({ message }));
  }
}

function* onGetSingleEmploye({ payload: { id } }) {
  try {
    const url = `/employee-panel/profile/${id}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getSingleEmployeSuccess(response?.myProfile));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get salary increment failed";
    yield put(getSingleEmployeFailure({ message }));
  }
}

function* onAddIncrement({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id
      ? `/level-and-section/section/update-section/${id}`
      : `/level-and-section/section/create-section`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster("success", `Increment ${id ? "Updated" : "Added"} Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} Increment Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetSingleIncrement({ payload: { id } }) {
  try {
    const url = `/salary increment/${id}`;
    const response = yield call(get, url);
    // console.log(`response`, response);
    if (response) {
      yield put(getSingleIncrementSuccess(response?.increment));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single salary increment failed";
    toaster("error", message);
    yield put(getSingleIncrementFailure({ message }));
  }
}

function* onDeleteIncrement({ payload: { id, successCallBack } }) {
  try {
    const url = `/salary increment/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteIncrementSuccess(response));
      toaster("success", "Increment Deleted successfully");
      successCallBack();
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Delete salary increment failed";
    toaster("error", message);
    yield put(deleteIncrementFailure({ message }));
  }
}

export function* incrementSaga() {
  yield takeEvery(getIncrementList.type, onGetIncrement);
  yield takeEvery(addIncrementData.type, onAddIncrement);
  yield takeEvery(getSingleIncrement.type, onGetSingleIncrement);
  yield takeEvery(getSingleEmploye.type, onGetSingleEmploye);
  yield takeEvery(deleteIncrement.type, onDeleteIncrement);
}
