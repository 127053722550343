import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  performanceList: [],
  performanceRattingList: {},
  loading: false,
  error: "",
  singlePerformance: {},
  adding: false,
};

const performanceSlice = createSlice({
  name: "performance",
  initialState: initialState,
  reducers: {
    resetPerformance: (state, action) => {
      state.performanceList = [];
      state.performanceRattingList = {};
      state.loading = false;
      state.error = "";
      state.singlePerformance = {};
      state.adding = false;
    },
    getPerformanceList: (state, action) => {
      state.loading = true;
    },
    getPerformanceListSuccess: (state, action) => {
      state.performanceList = action.payload;
      state.loading = false;
    },
    getPerformanceListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    getPerformanceRattingList: (state, action) => {
      state.loading = true;
    },
    getPerformanceRattingListSuccess: (state, action) => {
      state.performanceRattingList = action.payload;
      state.loading = false;
    },
    getPerformanceRattingListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addPerformanceData: (state, action) => {},
    getSinglePerformance: (state, action) => {
      state.loading = true;
    },
    getSinglePerformanceSuccess: (state, action) => {
      state.singlePerformance = action.payload;
      state.loading = false;
    },
    getSinglePerformanceFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deletePerformance: (state, action) => {
      state.adding = true;
    },
    deletePerformanceSuccess: (state, action) => {
      state.adding = false;
    },
    deletePerformanceFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getPerformanceList,
  getPerformanceListSuccess,
  getPerformanceListFailure,
  getPerformanceRattingList,
  getPerformanceRattingListSuccess,
  getPerformanceRattingListFailure,
  addPerformanceData,
  getSinglePerformance,
  getSinglePerformanceSuccess,
  getSinglePerformanceFailure,
  deletePerformance,
  deletePerformanceSuccess,
  deletePerformanceFailure,
  resetPerformance,
} = performanceSlice.actions;

export default performanceSlice.reducer;
