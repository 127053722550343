import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  questionList: {},
  loading: false,
  error: "",
  singleQuestion: {},
  adding: false,
};

const questionSlice = createSlice({
  name: "question",
  initialState: initialState,
  reducers: {
    storeQuestion: (state, { payload: { name, data } }) => {
      state[name] = data;
    },
    getQuestionList: (state, action) => {
      state.loading = true;
    },
    getQuestionListSuccess: (state, action) => {
      state.questionList = action.payload;
      state.loading = false;
    },
    getQuestionListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addQuestionData: (state, action) => {},
    getSingleQuestion: (state, action) => {
      state.loading = true;
    },
    getSingleQuestionSuccess: (state, { payload }) => {
      const { options, ...rest } = payload;
      const newOptions = options.map((data) => ({
        name: data?.name,
        isAnswer: data?.isAnswer,
      }));
      state.singleQuestion = { ...rest, options: newOptions };
      state.loading = false;
    },
    getSingleQuestionFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteQuestion: (state, action) => {
      state.adding = true;
    },
    deleteQuestionSuccess: (state, action) => {
      state.adding = false;
    },
    deleteQuestionFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getQuestionList,
  getQuestionListSuccess,
  getQuestionListFailure,
  addQuestionData,
  getSingleQuestion,
  getSingleQuestionSuccess,
  getSingleQuestionFailure,
  deleteQuestion,
  deleteQuestionSuccess,
  deleteQuestionFailure,
  getSearchUser,
  getSearchUserSuccess,
  getSearchUserFailure,
  storeQuestion,
} = questionSlice.actions;

export default questionSlice.reducer;
