import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  incrementList: [],
  loading: false,
  error: "",
  singleIncrement: {},
  singleEmploye: {},
  adding: false,
};

const incrementSlice = createSlice({
  name: "increment",
  initialState: initialState,
  reducers: {
    resetEmployee: (state, action) => {
      state.incrementList = [];
      state.loading = false;
      state.error = "";
      state.singleIncrement = {};
      state.singleEmploye = {};
      state.adding = false;
    },
    getIncrementList: (state, action) => {
      state.loading = true;
    },
    getIncrementListSuccess: (state, action) => {
      state.incrementList = action.payload;
      state.loading = false;
    },
    getIncrementListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addIncrementData: (state, action) => {},
    getSingleIncrement: (state, action) => {
      state.loading = true;
    },
    getSingleIncrementSuccess: (state, action) => {
      state.singleIncrement = action.payload;
      state.loading = false;
    },
    getSingleIncrementFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    getSingleEmploye: (state, action) => {
      state.loading = true;
    },
    getSingleEmployeSuccess: (state, action) => {
      state.singleEmploye = action.payload;
      state.loading = false;
    },
    getSingleEmployeFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteIncrement: (state, action) => {
      state.adding = true;
    },
    deleteIncrementSuccess: (state, action) => {
      state.adding = false;
    },
    deleteIncrementFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getIncrementList,
  getIncrementListSuccess,
  getIncrementListFailure,
  addIncrementData,
  getSingleIncrement,
  getSingleIncrementSuccess,
  getSingleIncrementFailure,
  getSingleEmploye,
  getSingleEmployeSuccess,
  getSingleEmployeFailure,
  deleteIncrement,
  deleteIncrementSuccess,
  deleteIncrementFailure,
  resetEmployee,
} = incrementSlice.actions;

export default incrementSlice.reducer;
