import { call, put, takeEvery } from "redux-saga/effects";
import { get, patch, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  addInterviewResultData,
  addOfferLetterData,
  getIntervieweeList,
  getIntervieweeListFailure,
  getIntervieweeListSuccess,
  getSingleInterviewee,
  getSingleIntervieweeFailure,
  getSingleIntervieweeSuccess,
  getSingleInterviewResult,
  getSingleInterviewResultFailure,
  getSingleInterviewResultSuccess,
  getViewOfferLetter,
  getViewOfferLetterFailure,
  getViewOfferLetterSuccess,
  storeQuestion,
} from "./IntervieweeSlice";

function* onGetInterviewee({
  payload: {
    page = 1,
    limit,
    interviewerId = "",
    interviewStatus = "",
    jobPostId = "",
  },
}) {
  try {
    const url = `/employee-panel/interview/data/?interviewerId=${interviewerId}&limit=${limit}&pageNo=${page}&interviewStatus=${interviewStatus}&jobPostId=${jobPostId}`;
    const response = yield call(get, url);

    if (response) {
      yield put(getIntervieweeListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get interviewee Failed";
    yield put(getIntervieweeListFailure({ message }));
  }
}
function* onGetSingleInterviewee({ payload: { id = "" } }) {
  try {
    const url = `/employee-panel/interview/${id}`;
    const response = yield call(get, url);
    // console.log("responseee", response?.interviewee?.jobPost?.question);
    response?.interviewee?.jobPost?.question?.forEach((question, index) => {
      // product.receivedQty = product?.quantity;
      question.questionId = question?._id;
      question.achievedMarks = 0;
      question.outOfMarks = question?.mark;
    });
    // console.log(
    //   "response?.interviewee?.jobPost?.question",
    //   response?.interviewee?.jobPost?.question
    // );
    yield put(
      storeQuestion({
        name: "questionInfo",
        value: response?.interviewee?.jobPost?.question,
      })
    );
    if (response) {
      yield put(getSingleIntervieweeSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single interviewee Failed";
    yield put(getSingleIntervieweeFailure({ message }));
  }
}
function* onGetSingleInterviewResult({ payload: { id = "" } }) {
  try {
    const url = `/employee-panel/interview/get-intereview-result-by-applicant/${id}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getSingleInterviewResultSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single interviewee result Failed";
    yield put(getSingleInterviewResultFailure({ message }));
  }
}
function* onGetViewOfferLetter({ payload: { id = "" } }) {
  try {
    const url = `/employee-panel/interview/show-offer-letter/${id}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getViewOfferLetterSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get view offer letter Failed";
    yield put(getViewOfferLetterFailure({ message }));
  }
}
function* onAddInterviewResultData({
  payload: {
    data,
    options: { setSubmitting, history, id },
  },
}) {
  try {
    const url = `/employee-panel/interview/intereview-result-submit/${id}`;
    const response = yield call(patch, url, data);
    if (response) {
      toaster("success", `Interview Result Submitted Successfully`);
      yield setSubmitting(true);
      history.push(`/employee/interviewee/${id}/result`);
      // yield put(getBankList({ page: 1, limit: 10 }));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || `Add Interview Result Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}
function* onAddOfferLetterData({
  payload: {
    data,
    options: {
      setSubmitting,
      resetForm,
      toggle,
      history,
      applicationId,
      isAdmin,
    },
  },
}) {
  try {
    const url = isAdmin
      ? `/career/offer-letter-create`
      : `/employee-panel/interview/offer-letter-create`;
    const response = yield call(post, url, data);
    if (response) {
      toaster("success", `Offer letter added successfully`);
      yield setSubmitting(false);
      yield resetForm();
      history.push(
        isAdmin
          ? `/admin/offer-letter-list/${applicationId}/view`
          : `/employee/interviewee/${applicationId}/offer-letter`
      );
      toggle();
    }
  } catch (error) {
    const message = error?.response?.data?.message || `Add Offer Letter Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

export function* intervieweeSaga() {
  yield takeEvery(getIntervieweeList.type, onGetInterviewee);
  yield takeEvery(getSingleInterviewee.type, onGetSingleInterviewee);
  yield takeEvery(getSingleInterviewResult.type, onGetSingleInterviewResult);
  yield takeEvery(addInterviewResultData.type, onAddInterviewResultData);
  yield takeEvery(addOfferLetterData.type, onAddOfferLetterData);
  yield takeEvery(getViewOfferLetter.type, onGetViewOfferLetter);
}
