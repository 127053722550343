import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  addLevelData,
  deleteLevel,
  deleteLevelFailure,
  deleteLevelSuccess,
  getLevelList,
  getLevelListFailure,
  getLevelListSuccess,
  getSingleLevel,
  getSingleLevelFailure,
  getSingleLevelSuccess,
} from "./LevelSlice";

function* onGetLevel({ payload: { page = 1, limit } }) {
  try {
    const url = `/level-and-section/level/get-levels?limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getLevelListSuccess(response?.getData));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get Level Failed";
    toaster("error", message);
    yield put(getLevelListFailure({ message }));
  }
}

function* onAddLevel({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id
      ? `/level-and-section/level/update-leve/${id}`
      : `/level-and-section/level/create-level`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster("success", `Level ${id ? "Updated" : "Added"} Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || `${id ? "Update" : "Add"} Level Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetSingleLevel({ payload: { id } }) {
  try {
    const url = `/level/${id}`;
    const response = yield call(get, url);
    // console.log(`response`, response);
    if (response) {
      yield put(getSingleLevelSuccess(response?.level));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get single level failed";
    toaster("error", message);
    yield put(getSingleLevelFailure({ message }));
  }
}

function* onDeleteLevel({ payload: { id, successCallBack } }) {
  try {
    const url = `/level/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteLevelSuccess(response));
      toaster("success", "Level Deleted successfully");
      successCallBack();
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Delete level failed";
    toaster("error", message);
    yield put(deleteLevelFailure({ message }));
  }
}

export function* levelSaga() {
  yield takeEvery(getLevelList.type, onGetLevel);
  yield takeEvery(addLevelData.type, onAddLevel);
  yield takeEvery(getSingleLevel.type, onGetSingleLevel);
  yield takeEvery(deleteLevel.type, onDeleteLevel);
}
