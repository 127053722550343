import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  attendanceList: [],
  loading: false,
  error: "",
  checkAttendance: {},
  adding: false,
};

const attendanceSlice = createSlice({
  name: "attendance",
  initialState: initialState,
  reducers: {
    getAttendanceList: (state, action) => {
      state.loading = true;
    },
    getAttendanceListSuccess: (state, action) => {
      state.attendanceList = action.payload;
      state.loading = false;
    },
    getAttendanceListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addAttendanceData: (state, action) => {},
    getDailyAttendance: (state, action) => {
      state.loading = true;
    },
    getDailyAttendanceSuccess: (state, action) => {
      state.checkAttendance = action.payload;
      state.loading = false;
    },
    getDailyAttendanceFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteAttendance: (state, action) => {
      state.adding = true;
    },
    deleteAttendanceSuccess: (state, action) => {
      state.adding = false;
    },
    deleteAttendanceFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getAttendanceList,
  getAttendanceListSuccess,
  getAttendanceListFailure,
  addAttendanceData,
  getDailyAttendance,
  getDailyAttendanceSuccess,
  getDailyAttendanceFailure,
  deleteAttendance,
  deleteAttendanceSuccess,
  deleteAttendanceFailure,
} = attendanceSlice.actions;

export default attendanceSlice.reducer;
