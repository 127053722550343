import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  leaveApplicationList: {},
  loading: false,
  error: "",
  singleLeaveApplication: {},
  adding: false,
};

const LeaveApplicationSlice = createSlice({
  name: "leaveApplication",
  initialState: initialState,
  reducers: {
    getLeaveApplicationList: (state, action) => {
      state.loading = true;
    },
    getLeaveApplicationListSuccess: (state, action) => {
      state.leaveApplicationList = action.payload;
      state.loading = false;
    },
    getLeaveApplicationListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addLeaveApplicationData: (state, action) => {},
    getSingleLeaveApplication: (state, action) => {
      state.loading = true;
    },
    getSingleLeaveApplicationSuccess: (state, action) => {
      state.singleLeaveApplication = action.payload;
      state.loading = false;
    },
    getSingleLeaveApplicationFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteLeaveApplication: (state, action) => {
      state.adding = true;
    },
    deleteLeaveApplicationSuccess: (state, action) => {
      state.adding = false;
    },
    deleteLeaveApplicationFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getLeaveApplicationList,
  getLeaveApplicationListSuccess,
  getLeaveApplicationListFailure,
  addLeaveApplicationData,
  getSingleLeaveApplication,
  getSingleLeaveApplicationSuccess,
  getSingleLeaveApplicationFailure,
  deleteLeaveApplication,
  deleteLeaveApplicationSuccess,
  deleteLeaveApplicationFailure,
} = LeaveApplicationSlice.actions;

export default LeaveApplicationSlice.reducer;
