import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bankList: {},
  loading: false,
  error: "",
  singleBank: {},
  adding: false,
};

const BankSlice = createSlice({
  name: "bank",
  initialState: initialState,
  reducers: {
    getBankList: (state, action) => {
      state.loading = true;
    },
    getBankListSuccess: (state, action) => {
      state.bankList = action.payload;
      state.loading = false;
    },
    getBankListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addBankData: (state, action) => {},
    getSingleBank: (state, action) => {
      state.loading = true;
    },
    getSingleBankSuccess: (state, action) => {
      state.singleBank = action.payload;
      state.loading = false;
    },
    getSingleBankFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteBank: (state, action) => {
      state.adding = true;
    },
    deleteBankSuccess: (state, action) => {
      state.adding = false;
    },
    deleteBankFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getBankList,
  getBankListSuccess,
  getBankListFailure,
  addBankData,
  getSingleBank,
  getSingleBankSuccess,
  getSingleBankFailure,
  deleteBank,
  deleteBankSuccess,
  deleteBankFailure,
} = BankSlice.actions;

export default BankSlice.reducer;
