import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  performanceRatting: {},
  loading: false,
  error: "",
  adding: false,
};

const performanceRattingSlice = createSlice({
  name: "performanceRatting",
  initialState: initialState,
  reducers: {
    getPerformanceRatting: (state, action) => {
      state.loading = true;
    },
    getPerformanceRattingSuccess: (state, action) => {
      state.performanceRatting = action.payload;
      state.loading = false;
    },
    getPerformanceRattingFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
  },
});

export const {
  getPerformanceRatting,
  getPerformanceRattingSuccess,
  getPerformanceRattingFailure,
} = performanceRattingSlice.actions;

export default performanceRattingSlice.reducer;
