import { call, put, takeEvery } from "redux-saga/effects";
import { get } from "../../../helpers/api_helper";
import {
  getPerformanceRatting,
  getPerformanceRattingFailure,
  getPerformanceRattingSuccess,
} from "./PerformanceRatingSlice";

function* onGetPerformanceRatting({
  payload: { id = "", year = new Date().getFullYear() },
}) {
  try {
    const url = `/employee-panel/performance-rating/get-performance-rating-by-employeeId-and-year/?employeeId=${id}&year=${year}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getPerformanceRattingSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get salary increment failed";
    yield put(getPerformanceRattingFailure({ message }));
  }
}

export function* performanceRattingSaga() {
  yield takeEvery(getPerformanceRatting.type, onGetPerformanceRatting);
}
