import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  promotionList: [],
  loading: false,
  error: "",
  singlePromotion: {},
  adding: false,
};

const employeePromotionSlice = createSlice({
  name: "promotion",
  initialState: initialState,
  reducers: {
    resetEmployee: (state, action) => {
      state.promotionList = [];
      state.loading = false;
      state.error = "";
      state.singlePromotion = {};
      state.adding = false;
    },
    getPromotionList: (state, action) => {
      state.loading = true;
    },
    getPromotionListSuccess: (state, action) => {
      state.promotionList = action.payload;
      state.loading = false;
    },
    getPromotionListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addPromotionData: (state, action) => {},
    getSinglePromotion: (state, action) => {
      state.loading = true;
    },
    getSinglePromotionSuccess: (state, action) => {
      state.singlePromotion = action.payload;
      state.loading = false;
    },
    getSinglePromotionFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },

    deletePromotion: (state, action) => {
      state.adding = true;
    },
    deletePromotionSuccess: (state, action) => {
      state.adding = false;
    },
    deletePromotionFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getPromotionList,
  getPromotionListSuccess,
  getPromotionListFailure,
  addPromotionData,
  getSinglePromotion,
  getSinglePromotionSuccess,
  getSinglePromotionFailure,
  deletePromotion,
  deletePromotionSuccess,
  deletePromotionFailure,
  resetEmployee,
} = employeePromotionSlice.actions;

export default employeePromotionSlice.reducer;
