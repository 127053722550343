import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  intervieweeList: {},
  singleInterviewee: {},
  offerLetterList: {},
  offerLetterData: {},
  singleInterviewResult: {},
  loading: true,
  error: "",
  adding: false,
};

const IntervieweeSlice = createSlice({
  name: "adminInterviewee",
  initialState: initialState,
  reducers: {
    getIntervieweeList: (state, action) => {
      state.loading = true;
    },
    getIntervieweeListSuccess: (state, action) => {
      state.intervieweeList = action.payload;
      state.loading = false;
    },
    getIntervieweeListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },

    getSingleInterviewResult: (state, action) => {
      state.loading = true;
    },
    getSingleInterviewResultSuccess: (state, action) => {
      state.singleInterviewResult = action.payload;
      state.loading = false;
    },
    getSingleInterviewResultFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addInterviewResultReviewData: (state, action) => {},
    sendEmail: (state, action) => {
      state.adding = true;
    },
    sendEmailSuccess: (state, action) => {
      state.adding = false;
    },
    sendEmailFail: (state, action) => {
      state.adding = false;
    },
    getOfferLetterList: (state, action) => {
      state.loading = true;
    },
    getOfferLetterListSuccess: (state, action) => {
      state.offerLetterList = action.payload;
      state.loading = false;
    },
    getOfferLetterListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    getAdminOfferLetter: (state, action) => {
      state.loading = true;
    },
    getAdminOfferLetterSuccess: (state, action) => {
      state.offerLetterData = action.payload;
      state.loading = false;
    },
    getAdminOfferLetterFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addOfferLetterNegotiateData: (state, action) => {},
  },
});

export const {
  sendEmail,
  sendEmailSuccess,
  sendEmailFail,
  addOfferLetterNegotiateData,
  getAdminOfferLetter,
  getAdminOfferLetterSuccess,
  getAdminOfferLetterFailure,
  getAdminOfferLetterList,
  getOfferLetterList,
  getOfferLetterListSuccess,
  getOfferLetterListFailure,
  getIntervieweeList,
  getSingleInterviewResult,
  getSingleInterviewResultSuccess,
  getSingleInterviewResultFailure,
  getIntervieweeListSuccess,
  getIntervieweeListFailure,
  addInterviewResultReviewData,
} = IntervieweeSlice.actions;

export default IntervieweeSlice.reducer;
