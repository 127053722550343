import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  applicantassessmentList: {},
  profileData: {},
  assessmentViewData: {},
  appliedPositionList: {},
  singleOfferLetterData: {},
  appliedPositionData: {},
  offerLetter: {},
  loading: false,
  error: "",
  adding: false,
  chartLoading: true,
  chartData: {},
  time: 0,
};

const applicantProfileSlice = createSlice({
  name: "applicant-profile",
  initialState: initialState,
  reducers: {
    getApplicantProfile: (state, action) => {
      state.loading = true;
    },
    getApplicantProfileSuccess: (state, action) => {
      state.profileData = action.payload;
      state.loading = false;
    },
    getApplicantProfileFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    updateProfile: (state, action) => {},
    updateApplicantPassword: (state, action) => {},
  },
});

export const {
  getApplicantProfile,
  getApplicantProfileSuccess,
  getApplicantProfileFailure,
  updateProfile,
  updateApplicantPassword,
} = applicantProfileSlice.actions;

export default applicantProfileSlice.reducer;
