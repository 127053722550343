import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  jobPostList: {},
  loading: false,
  error: "",
  singleJobPost: {},
  adding: false,
};

const JobPostSlice = createSlice({
  name: "jobPost",
  initialState: initialState,
  reducers: {
    storeJobPost: (state, { payload: { name, data } }) => {
      state[name] = data;
    },
    getJobPostList: (state, action) => {
      state.loading = true;
    },
    getJobPostListSuccess: (state, action) => {
      state.jobPostList = action.payload;
      state.loading = false;
    },
    getJobPostListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addJobPostData: (state, action) => {
      state.adding = action.payload.options.view ? true : false;
    },
    getSingleJobPost: (state, action) => {
      state.loading = true;
    },
    getSingleJobPostSuccess: (state, action) => {
      state.singleJobPost = action.payload;
      state.loading = false;
    },
    getSingleJobPostFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteJobPost: (state, action) => {
      state.adding = true;
    },
    deleteJobPostSuccess: (state, action) => {
      state.adding = false;
    },
    deleteJobPostFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getJobPostList,
  getJobPostListSuccess,
  getJobPostListFailure,
  addJobPostData,
  getSingleJobPost,
  getSingleJobPostSuccess,
  getSingleJobPostFailure,
  deleteJobPost,
  deleteJobPostSuccess,
  deleteJobPostFailure,
  storeJobPost,
} = JobPostSlice.actions;

export default JobPostSlice.reducer;
