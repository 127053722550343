import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  addPromotionData,
  deletePromotion,
  deletePromotionFailure,
  deletePromotionSuccess,
  getPromotionList,
  getPromotionListFailure,
  getPromotionListSuccess,
  getSinglePromotion,
  getSinglePromotionFailure,
  getSinglePromotionSuccess,
} from "./PromotionSlice";

function* onGetPromotion({
  payload: { page = 0, limit = 0, employeeId = "", year = "" },
}) {
  try {
    const url = `/employee-panel/promotion/data/?limit=${limit}&pageNo=${page}&employeeId=${employeeId}&year=${year}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getPromotionListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get promotion failed";
    yield put(getPromotionListFailure({ message }));
  }
}

function* onAddPromotion({
  payload: {
    data,
    options: { setSubmitting, resetForm, id, isSubmit, setConfirm },
  },
}) {
  try {
    const url = id
      ? `/employee-panel/promotion/${id}`
      : `/employee-panel/promotion/`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      if (response?.message) {
        yield setSubmitting(false);
        yield resetForm();
        return toaster("warning", response?.message);
      }
      toaster("success", `Promotion ${id ? "Updated" : "Added"} Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      isSubmit(true);
      yield setConfirm(false);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} Promotion Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetSinglePromotion({ payload: { id } }) {
  try {
    const url = `/employee-panel/promotion/${id}`;
    const response = yield call(get, url);
    // console.log(`response`, response);
    if (response) {
      yield put(getSinglePromotionSuccess(response?.promotion));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single promotion failed";
    toaster("error", message);
    yield put(getSinglePromotionFailure({ message }));
  }
}

function* onDeletePromotion({ payload: { id, successCallBack } }) {
  try {
    const url = `/employee-panel/promotion/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deletePromotionSuccess(response));
      toaster("success", "Promotion Deleted successfully");
      successCallBack();
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Delete promotion failed";
    toaster("error", message);
    yield put(deletePromotionFailure({ message }));
  }
}

export function* employeePromotionSaga() {
  yield takeEvery(getPromotionList.type, onGetPromotion);
  yield takeEvery(addPromotionData.type, onAddPromotion);
  yield takeEvery(getSinglePromotion.type, onGetSinglePromotion);
  yield takeEvery(deletePromotion.type, onDeletePromotion);
}
