import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  addKpiAchievedData,
  addKpiSelectionData,
  addKpiTargetData,
  addPerformanceRatingData,
  deleteKpiSelection,
  deleteKpiSelectionFailure,
  deleteKpiSelectionSuccess,
  deletePerformanceRatting,
  deletePerformanceRattingFailure,
  deletePerformanceRattingSuccess,
  getKpiAchievedList,
  getKpiAchievedListFailure,
  getKpiAchievedListSuccess,
  getKpiSelectedSelectionList,
  getKpiSelectedSelectionListFailure,
  getKpiSelectedSelectionListSuccess,
  getKpiSelectedTargetList,
  getKpiSelectedTargetListFailure,
  getKpiSelectedTargetListSuccess,
  getKpiSelectionList,
  getKpiSelectionListFailure,
  getKpiSelectionListSuccess,
  getPerformanceRatingList,
  getPerformanceRatingListFailure,
  getPerformanceRatingListSuccess,
  getSingleKpiSelection,
  getSingleKpiSelectionFailure,
  getSingleKpiSelectionSuccess,
  getSinglePerformance,
  getSinglePerformanceFailure,
  getSinglePerformanceSuccess,
  storeKpiSelection,
} from "./kpiSelectionSlice";

function* onGetKpiSelection({
  payload: { page = 0, limit = 0, departmentId, year },
}) {
  try {
    const url = `/employee-panel/performance-rating/all-kpi-list/?limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);

    const url2 = `/employee-panel/performance-rating/department-selected-kpi-list/?limit=${limit}&pageNo=${page}&department=${departmentId}&year=${year}`;
    let selectedKpiList = yield call(get, url2);

    const selectedKpiID = selectedKpiList?.kpis?.kpi?.map(
      (el) => el?.kpiId?._id
    );
    // console.log(selectedKpiID, response?.kpis);
    response?.kpis?.forEach((el) => {
      el.selected = selectedKpiID?.includes(el?._id) ? true : false;
    });

    // console.log(response?.kpis, selectedKpiList?.kpis?.kpi);

    yield put(
      getKpiSelectionListSuccess({
        kpis: response?.kpis,
        selectedKpis: selectedKpiList?.kpis?.kpi,
      })
    );
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get salary kpiSelection failed";
    yield put(getKpiSelectionListFailure({ message }));
  }
}

function* onAddKpiSelection({
  payload: {
    data,
    options: { id, toggle },
  },
}) {
  try {
    const url = id
      ? `/level-and-section/section/update-section/${id}`
      : `/employee-panel/performance-rating/kpi-selection`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster(
        "success",
        `KPI Selection ${id ? "Updated" : "Added"} Successfully`
      );
      // yield setSubmitting(false);
      // yield resetForm();
      toggle();
      yield put(storeKpiSelection({ name: "adding", data: false }));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} KpiSelection Failed`;
    toaster("error", message);
    // yield setSubmitting(false);
  }
}

function* onGetSelectedKpiSelection({
  payload: { page = 1, limit = 10, departmentId, year, employeeId },
}) {
  try {
    const url = `/employee-panel/performance-rating/department-selected-kpi-list/?limit=${limit}&pageNo=${page}&department=${departmentId}&year=${year}`;
    const response = yield call(get, url);

    const url2 = `/employee-panel/performance-rating/get-performance-rating-by-employeeId-and-year/?employeeId=${employeeId}&year=${year}`;
    const selectedUserKpis = yield call(get, url2);

    if (response) {
      response?.kpis?.kpi?.forEach((el) => {
        let selected = false;
        let target = "";
        selectedUserKpis?.performanceRatingDetails?.forEach((item) => {
          // console.log("el", el);
          if (item?.kpiId?._id === el?.kpiId?._id) {
            selected = true;
            target = item?.target;
          }
        });

        el.selected = selected;
        el.target = target;
      });
      yield put(getKpiSelectedSelectionListSuccess(response?.kpis?.kpi));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get salary kpiSelection failed";
    yield put(getKpiSelectedSelectionListFailure({ message }));
  }
}

function* onGetKpiAchieved({ payload: { year, employeeId } }) {
  try {
    const url = `/employee-panel/performance-rating/get-performance-rating-by-employeeId-and-year/?employeeId=${employeeId}&year=${year}`;
    const response = yield call(get, url);

    if (response) {
      response?.performanceRatingDetails?.forEach((el) => {
        el.tillNow = "";
      });
      yield put(
        getKpiAchievedListSuccess({
          achievedData: response,
          achievedList: response?.performanceRatingDetails,
        })
      );
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get Achieved KPI failed";
    yield put(getKpiAchievedListFailure({ message }));
  }
}

function* onAddKpiTarget({
  payload: {
    data,
    options: { toggle, id },
  },
}) {
  try {
    const url = id
      ? `/level-and-section/section/update-section/${id}`
      : `/employee-panel/performance-rating/create-kpi-target`;
    const response = yield call(id ? patch : post, url, data);

    if (response) {
      toaster("success", `KPI Target ${id ? "Updated" : "Added"} Successfully`);
      toggle();
      yield put(storeKpiSelection({ name: "adding", data: false }));
      // yield setSubmitting(false);
      // yield resetForm();
      //   toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} KPI Target Failed`;
    toaster("error", message);
    // yield setSubmitting(false);
  }
}

function* onAddKpiAchieved({
  payload: {
    data,
    options: { id, handleShow, toggle },
  },
}) {
  try {
    const url = id
      ? `/level-and-section/section/update-section/${id}`
      : `/employee-panel/performance-rating`;
    const response = yield call(id ? patch : post, url, data);

    if (response) {
      toaster(
        "success",
        `KPI Achieved ${id ? "Updated" : "Added"} Successfully`
      );
      handleShow(true);
      toggle();
      yield put(storeKpiSelection({ name: "adding", data: false }));
      // yield setSubmitting(false);
      // yield resetForm();

      //   toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} KPI Achieved Failed`;
    toaster("error", message);
    // yield setSubmitting(false);
  }
}

function* onAddPerformanceRatting({
  payload: {
    data,
    options: { id, toggle, setSubmitting, resetForm },
  },
}) {
  try {
    const url = id
      ? `/level-and-section/section/update-section/${id}`
      : `/employee-panel/performance-rating/performance-rating-submit`;
    const response = yield call(id ? patch : post, url, data);

    if (response) {
      toaster(
        "success",
        `Performance Rating ${id ? "Updated" : "Added"} Successfully`
      );
      yield setSubmitting(false);
      yield resetForm();

      toggle(true);
      yield put(storeKpiSelection({ name: "adding", data: false }));
      // toggle({ api: true });
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} Performance Rating Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetPerformanceRatting({
  payload: { limit = 10, page = 1, year = "", performanceGradeId = "" },
}) {
  try {
    const url = `/employee-panel/performance-rating/data/?limit=${limit}&pageNo=${page}&employeeId&year=${year}&performanceGradeId=${performanceGradeId}`;
    const response = yield call(get, url);

    if (response) {
      yield put(getPerformanceRatingListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get Achieved KPI failed";
    yield put(getPerformanceRatingListFailure({ message }));
  }
}

function* onGetSelectedKpiTarget({ payload: { employeeId, year } }) {
  try {
    const url = `/employee-panel/performance-rating/get-performance-rating-by-employeeId-and-year/?employeeId=${employeeId}&year=${year}`;
    const response = yield call(get, url);

    if (response) {
      // console.log(response);
      yield put(getKpiSelectedTargetListSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get salary kpiSelection failed";
    yield put(getKpiSelectedTargetListFailure({ message }));
  }
}

function* onGetSingleKpiSelection({ payload: { id } }) {
  try {
    const url = `/salary kpiSelection/${id}`;
    const response = yield call(get, url);
    // console.log(`response`, response);
    if (response) {
      yield put(getSingleKpiSelectionSuccess(response?.kpiSelection));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single salary kpiSelection failed";
    toaster("error", message);
    yield put(getSingleKpiSelectionFailure({ message }));
  }
}

function* onGetSinglePerformance({
  payload: { id, year = "", performanceGradeId = "" },
}) {
  try {
    const url = `/employee-panel/performance-rating/data/?limit=10&pageNo=1&employeeId=${id}&year=${year}&performanceGradeId=${performanceGradeId}`;
    const response = yield call(get, url);

    if (response) {
      yield put(getSinglePerformanceSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single Performance Rating failed";
    toaster("error", message);
    yield put(getSinglePerformanceFailure({ message }));
  }
}

function* onDeletePerformance({ payload: { id, successCallBack } }) {
  try {
    const url = `/employee-panel/performance-rating/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deletePerformanceRattingSuccess(response));
      toaster("success", "Performance Ratting Deleted successfully");
      successCallBack();
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Delete Performance Ratting failed";
    toaster("error", message);
    yield put(deletePerformanceRattingFailure({ message }));
  }
}

function* onDeleteKpiSelection({ payload: { id, successCallBack } }) {
  try {
    const url = `/salary-kpiSelection/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteKpiSelectionSuccess(response));
      toaster("success", "KpiSelection Deleted successfully");
      successCallBack();
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Delete salary kpiSelection failed";
    toaster("error", message);
    yield put(deleteKpiSelectionFailure({ message }));
  }
}

export function* kpiSelectionSaga() {
  yield takeEvery(getKpiSelectionList.type, onGetKpiSelection);
  yield takeEvery(addKpiSelectionData.type, onAddKpiSelection);
  yield takeEvery(addKpiTargetData.type, onAddKpiTarget);
  yield takeEvery(getKpiAchievedList.type, onGetKpiAchieved);
  yield takeEvery(addKpiAchievedData.type, onAddKpiAchieved);
  yield takeEvery(addPerformanceRatingData.type, onAddPerformanceRatting);
  yield takeEvery(getPerformanceRatingList.type, onGetPerformanceRatting);
  yield takeEvery(getKpiSelectedSelectionList.type, onGetSelectedKpiSelection);
  yield takeEvery(getKpiSelectedTargetList.type, onGetSelectedKpiTarget);
  yield takeEvery(getSingleKpiSelection.type, onGetSingleKpiSelection);
  yield takeEvery(getSinglePerformance.type, onGetSinglePerformance);
  yield takeEvery(deletePerformanceRatting.type, onDeletePerformance);
  yield takeEvery(deleteKpiSelection.type, onDeleteKpiSelection);
}
