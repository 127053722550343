import { call, put, takeEvery } from "redux-saga/effects";
import { get, patch, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  addAptitudeQuestion,
  addCorporateQuestions,
  addQuestionsSuccess,
  addWritingQuestion,
  getAllQuestions,
  getAllQuestionsFailure,
  getAllQuestionsSuccess,
  getAptitudeQuestions,
  getAptitudeQuestionsFailure,
  getAptitudeQuestionsSuccess,
  getSingleAptitudeQuestion,
  getSingleAptitudeQuestionFailure,
  getSingleAptitudeQuestionSuccess,
  getSingleCorporateQuestion,
  getSingleCorporateQuestionFailure,
  getSingleCorporateQuestionSuccess,
  getSingleWritingQuestion,
  getSingleWritingQuestionFailure,
  getSingleWritingQuestionSuccess,
  getWritingQuestions,
  getWritingQuestionsFailure,
  getWritingQuestionsSuccess,
  removeQuestion,
  removeQuestionFailure,
  removeQuestionSuccess,
  updateQuestionStatus,
  updateQuestionStatusFailure,
  updateQuestionStatusSuccess,
} from "./CorporateQuestionSlice.js";

function* onGetAllQuestions({ payload: { passageId, type, view } }) {
  try {
    const url = `/admin/${type}/${passageId}/allquestion`;
    const url1 = `/admin/${type}/${passageId}/get-question-type-based-number`;
    const response = yield call(get, url);
    let questionStatus;
    if (view) {
      questionStatus = yield call(get, url1);
    }
    yield put(
      getAllQuestionsSuccess({
        questions: response?.getData,
        status: questionStatus?.obj || {},
      })
    );
  } catch (error) {
    const message = error?.response?.data?.message || "Get question failed";
    toaster("error", message);
    yield put(getAllQuestionsFailure({ message }));
  }
}

function* onAddQuestion({
  payload: {
    data,
    options: {
      setSubmitting,
      resetForm,
      history,
      id,
      type,
      groupId,
      questionType,
      isMore,
      setIsAddMore,
      passageId,
    },
  },
}) {
  try {
    let api = "";
    switch (type) {
      case "listening":
        api = id
          ? `/admin/listening/update-specific-question/${groupId}/${id}`
          : "/admin/listening/create-listening-question";
        break;
      case "reading":
        api = id
          ? `/admin/reading/update-specific-question/${groupId}/${id}`
          : "/admin/reading/create-reading-question";
        break;
      default:
        break;
    }
    const url = api;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster("success", `Question ${id ? "updated" : "added"} successfully`);
      setSubmitting(false);
      resetForm();
      if (isMore) {
        yield put(addQuestionsSuccess());
        setIsAddMore(false);
      } else {
        history.goBack();
      }
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} question Failed`;
    toaster("error", message);
    setSubmitting(false);
  }
}

function* onAddWritingQuestion({
  payload: {
    data,
    options: { setSubmitting, resetForm, history, id, serviceId, type },
  },
}) {
  try {
    const url = id
      ? `/admin/writing/update-writing/${id}`
      : `/admin/writing/writing-question-create`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster(
        "success",
        `Writing question ${id ? "updated" : "added"} successfully`
      );
      setSubmitting(false);
      resetForm();
      history.push(`/admin/services/${serviceId}/${type}`);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} question Failed`;
    toaster("error", message);
    setSubmitting(false);
  }
}

function* onGetWritingQuestion({
  payload: { serviceId = "", level = "", page = 1, limit = 10 },
}) {
  try {
    const url = `/admin/writing/get-writing/${serviceId}/${level}`;
    const response = yield call(get, url);
    yield put(getWritingQuestionsSuccess(response?.getData));
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get writing question failed";
    toaster("error", message);
    yield put(getWritingQuestionsFailure({ message }));
  }
}

function* onGetSingleWritingQuestion({ payload: { id } }) {
  try {
    const url = `/admin/writing/get-single-writing/${id}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getSingleWritingQuestionSuccess(response?.getData));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single writing question failed";
    toaster("error", message);
    yield put(getSingleWritingQuestionFailure({ message }));
  }
}

function* onAddAptitudeQuestion({
  payload: {
    data,
    options: {
      setSubmitting,
      resetForm,
      history,
      id,
      serviceId,
      type,
      isMore,
      setIsAddMore,
    },
  },
}) {
  try {
    const url = id
      ? `/admin/gmat/update-gmat/${id}`
      : `/admin/gmat/gmat-question-create`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster(
        "success",
        `Aptitude question ${id ? "updated" : "added"} successfully`
      );
      setSubmitting(false);
      resetForm();
      if (isMore) {
        yield put(addQuestionsSuccess());
        setIsAddMore(false);
      } else {
        history.push(`/admin/services/${serviceId}/${type}`);
      }
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} question Failed`;
    toaster("error", message);
    setSubmitting(false);
  }
}

function* onGetAptitudeQuestion({
  payload: { serviceId = "", level = "", page = 1, limit = 10 },
}) {
  try {
    const url = `/admin/gmat/get-gmat/${serviceId}/${level}`;
    const response = yield call(get, url);
    // console.log(`🦄 ~ file: saga.js:205 ~ response`, response);
    yield put(getAptitudeQuestionsSuccess(response?.getData));
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get aptitude question failed";
    toaster("error", message);
    yield put(getAptitudeQuestionsFailure({ message }));
  }
}

function* onGetSingleAptitudeQuestion({ payload: { id } }) {
  try {
    const url = `/admin/gmat/get-single-gmat/${id}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getSingleAptitudeQuestionSuccess(response?.getData));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single aptitude question failed";
    toaster("error", message);
    yield put(getSingleAptitudeQuestionFailure({ message }));
  }
}

function* onGetSingleQuestion({
  payload: { type, questionId, questionType, passageId },
}) {
  try {
    const url = `/admin/${type}/specific-questions-under-type/${passageId}/${questionType}`;
    const url1 = `/admin/${type}/${passageId}/get-current-question-serial`;
    let data = yield call(get, url);
    let serial;
    if (!questionId) {
      serial = yield call(get, url1);
    }
    const question = data?.getData
      ? data?.getData?.Que?.question?.find((el) => el?._id === questionId)
      : {};
    // console.log(`question`, question, data);
    yield put(
      getSingleCorporateQuestionSuccess({
        data: data?.getData || {},
        question: question || {},
        number: questionId ? question?.questionNumber : serial?.getData,
      })
    );
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single question failed";
    toaster("error", message);
    yield put(getSingleCorporateQuestionFailure({ message }));
  }
}

function* onUpdateQuestionStatus({
  payload: { passageId, questionType, successCallBack, type },
}) {
  try {
    const url = `/admin/${type}/question-status-update/${passageId}/${questionType}`;
    const response = yield call(patch, url, {});
    if (response) {
      yield put(updateQuestionStatusSuccess(response));
      toaster("success", "Question update successfully");
      successCallBack && successCallBack();
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Update question failed";
    toaster("error", message);
    yield put(updateQuestionStatusFailure({ message }));
  }
}

function* onDeleteQuestion({
  payload: { passageId, groupId, questionId, successCallBack, type },
}) {
  try {
    const url = `/admin/${type}/remove-specific-question/${passageId}/${groupId}/${questionId}`;
    const response = yield call(patch, url, {});
    if (response) {
      yield put(removeQuestionSuccess(response));
      toaster("success", "Question remove successfully");
      successCallBack && successCallBack();
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Remove question failed";
    toaster("error", message);
    yield put(removeQuestionFailure({ message }));
  }
}

export function* corporateQuestionSaga() {
  yield takeEvery(addCorporateQuestions.type, onAddQuestion);
  yield takeEvery(getSingleCorporateQuestion.type, onGetSingleQuestion);
  yield takeEvery(removeQuestion.type, onDeleteQuestion);
  yield takeEvery(addWritingQuestion.type, onAddWritingQuestion);
  yield takeEvery(getWritingQuestions.type, onGetWritingQuestion);
  yield takeEvery(addAptitudeQuestion.type, onAddAptitudeQuestion);
  yield takeEvery(getAptitudeQuestions.type, onGetAptitudeQuestion);
  yield takeEvery(getSingleAptitudeQuestion.type, onGetSingleAptitudeQuestion);
  yield takeEvery(getSingleWritingQuestion.type, onGetSingleWritingQuestion);
  yield takeEvery(getAllQuestions.type, onGetAllQuestions);
  yield takeEvery(updateQuestionStatus.type, onUpdateQuestionStatus);
}
