import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../helpers/api_helper";
import { toaster } from "../../helpers/custom/Toast";
import {
  addPaidSalary,
  addSalaryData,
  deleteSalary,
  deleteSalaryFailure,
  deleteSalarySuccess,
  getBankSheet,
  getBankSheetFailure,
  getBankSheetSuccess,
  getSalaryList,
  getSalaryListFailure,
  getSalaryListSuccess,
  getSalarySheet,
  getSalarySheetFailure,
  getSalarySheetSuccess,
  getSingleSalary,
  getSingleSalaryFailure,
  getSingleSalarySuccess,
} from "./salarySlice";

function* onGetSalary({
  payload: {
    concernCompany = "",
    department = "",
    designation = "",
    salaryType = "",
    month = "",
    fromDate = "",
    toDate = "",
  },
}) {
  try {
    const url = `/salary/get-data-for-salary-generate/?concernCompany=${concernCompany}&department=${department}&designation=${designation}&salaryType=${salaryType}&month=${month}&fromDate=${fromDate}&toDate=${toDate}`;
    const response = yield call(get, url);
    if (response) {
      response?.employees?.forEach((el) => {
        el.selected = false;
      });
      yield put(getSalaryListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get salary failed";
    yield put(getSalaryListFailure({ message }));
  }
}

function* onAddSalary({
  payload: {
    data,
    options: { id = "", isSubmit },
  },
}) {
  try {
    const url = id
      ? `/salary/salary-generate/${id}`
      : `/salary/salary-generate`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster("success", `Salary ${id ? "Updated" : "Generate"} Successfully`);
      // yield setSubmitting(false);
      // yield resetForm();
      isSubmit(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} Salary Failed`;
    toaster("error", message);
    // yield setSubmitting(false);
  }
}

function* onGetSalarySheet({
  payload: {
    concernCompany = "",
    department = "",
    designation = "",
    salaryType = "",
    month = "2023-01",
    status = "",
    limit = 10,
    page = 0,
  },
}) {
  try {
    const url = `/salary/get-salary-sheet/?concernCompany=${concernCompany}&department=${department}&designation=${designation}&salaryType=${salaryType}&month=${month}&status=${status}&limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    if (response) {
      response?.salarySheets?.forEach((el) => {
        el.selected = false;
      });
      yield put(getSalarySheetSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get salary failed";
    yield put(getSalarySheetFailure({ message }));
  }
}

function* onAddPaidSalary({
  payload: {
    data,
    options: { id = "", isSubmit },
  },
}) {
  try {
    const url = id ? `/salary/salary-paid${id}` : `/salary/salary-paid`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster("success", `Salary ${id ? "Updated" : "Paid"} Successfully`);
      // yield setSubmitting(false);
      // yield resetForm();
      isSubmit(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Paid"} Salary Failed`;
    toaster("error", message);
    // yield setSubmitting(false);
  }
}

function* onGetSingleSalary({ payload: { id = "" } }) {
  try {
    const url = `/salary/${id}`;
    const response = yield call(get, url);
    // console.log(`response`, response);
    if (response) {
      yield put(getSingleSalarySuccess(response?.payslip));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single salary failed";
    toaster("error", message);
    yield put(getSingleSalaryFailure({ message }));
  }
}

function* onGetBankSheet({
  payload: { status = "", month = "", bankId = "" },
}) {
  try {
    const url = `/salary/get-bank-sheet/?status=${status}&month=${month}&bankId=${bankId}`;
    const response = yield call(get, url);
    // console.log(`response`, response);
    if (response) {
      yield put(getBankSheetSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get bank sheet failed";
    toaster("error", message);
    yield put(getBankSheetFailure({ message }));
  }
}

function* onDeleteSalary({ payload: { id = "", successCallBack } }) {
  try {
    const url = `/salary/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteSalarySuccess(response));
      toaster("success", "Salary Deleted successfully");
      successCallBack(true);
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Delete salary failed";
    toaster("error", message);
    yield put(deleteSalaryFailure({ message }));
  }
}

export function* salarySaga() {
  yield takeEvery(getSalaryList.type, onGetSalary);
  yield takeEvery(addSalaryData.type, onAddSalary);
  yield takeEvery(getSalarySheet.type, onGetSalarySheet);
  yield takeEvery(addPaidSalary.type, onAddPaidSalary);
  yield takeEvery(getSingleSalary.type, onGetSingleSalary);
  yield takeEvery(getBankSheet.type, onGetBankSheet);
  yield takeEvery(deleteSalary.type, onDeleteSalary);
}
