import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  concernCompanyList: {},
  loading: false,
  error: "",
  singleConcernCompany: {},
  adding: false,
};

const concernCompanySlice = createSlice({
  name: "concernCompany",
  initialState: initialState,
  reducers: {
    getConcernCompanyList: (state, action) => {
      state.loading = true;
    },
    getConcernCompanyListSuccess: (state, action) => {
      state.concernCompanyList = action.payload;
      state.loading = false;
    },
    getConcernCompanyListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addConcernCompanyData: (state, action) => {},
    getSingleConcernCompany: (state, action) => {
      state.loading = true;
    },
    getSingleConcernCompanySuccess: (state, action) => {
      state.singleConcernCompany = action.payload;
      state.loading = false;
    },
    getSingleConcernCompanyFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteConcernCompany: (state, action) => {
      state.adding = true;
    },
    deleteConcernCompanySucces: (state, action) => {
      state.adding = false;
    },
    deleteConcernCompanyFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getConcernCompanyList,
  getConcernCompanyListSuccess,
  getConcernCompanyListFailure,
  addConcernCompanyData,
  getSingleConcernCompany,
  getSingleConcernCompanySuccess,
  getSingleConcernCompanyFailure,
  deleteConcernCompany,
  deleteConcernCompanySucces,
  deleteConcernCompanyFailure,
} = concernCompanySlice.actions;

export default concernCompanySlice.reducer;
