const toCapitalize = (value) => {
  return value?.charAt(0)?.toUpperCase() + value?.slice(1);
};
const toCapitalizeAll = (wordString) => {
  const words = wordString?.trim()?.split(" ");
  for (let i = 0; i < words?.length; i++) {
    words[i] = words[i]?.[0]?.toUpperCase() + words[i].substr(1);
  }
  const res = words?.join(" ");
  return res;
};

const textToUrl = (url) => {
  return url?.split(" ").join("-");
};

const urlToText = (url) => {
  return url?.split("-").join(" ");
};

export { toCapitalizeAll, toCapitalize, textToUrl, urlToText };

// export const handleHttps = (link) => {
//   return link?.s
// }
