import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  salaryIncrementList: [],
  loading: false,
  error: "",
  checkSalaryIncrement: {},
  incrementWithEmployee: {},
  adding: false,
};

const salaryIncrementSlice = createSlice({
  name: "salaryIncrement",
  initialState: initialState,
  reducers: {
    getSalaryIncrementList: (state, action) => {
      state.loading = true;
    },
    getSalaryIncrementListSuccess: (state, action) => {
      state.salaryIncrementList = action.payload;
      state.loading = false;
    },
    getSalaryIncrementListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addSalaryIncrementData: (state, action) => {},
    getSingleSalaryIncrement: (state, action) => {
      state.loading = true;
    },
    getSingleSalaryIncrementSuccess: (state, action) => {
      state.checkSalaryIncrement = action.payload;
      state.loading = false;
    },
    getSingleSalaryIncrementFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    getSingleSalaryIncrementByIdAndYear: (state, action) => {
      state.loading = true;
    },
    getSingleSalaryIncrementByIdAndYearSuccess: (state, action) => {
      state.incrementWithEmployee = action.payload;
      state.loading = false;
    },
    getSingleSalaryIncrementByIdAndYearFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },

    deleteSalaryIncrement: (state, action) => {
      state.adding = true;
    },
    deleteSalaryIncrementSuccess: (state, action) => {
      state.adding = false;
    },
    deleteSalaryIncrementFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getSalaryIncrementList,
  getSalaryIncrementListSuccess,
  getSalaryIncrementListFailure,
  addSalaryIncrementData,
  getSingleSalaryIncrement,
  getSingleSalaryIncrementSuccess,
  getSingleSalaryIncrementFailure,
  getSingleSalaryIncrementByIdAndYear,
  getSingleSalaryIncrementByIdAndYearSuccess,
  getSingleSalaryIncrementByIdAndYearFailure,
  deleteSalaryIncrement,
  deleteSalaryIncrementSuccess,
  deleteSalaryIncrementFailure,
} = salaryIncrementSlice.actions;

export default salaryIncrementSlice.reducer;
