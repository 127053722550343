import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  addServiceData,
  deleteService,
  deleteServiceFailure,
  deleteServiceSuccess,
  getServiceList,
  getServiceListFailure,
  getServiceListSuccess,
  getSingleService,
  getSingleServiceFailure,
  getSingleServiceSuccess,
} from "./ServiceSlice";

function* onGetService({ payload: { page = 1, limit } }) {
  try {
    const url = `/level-and-section/section/get-sections?limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getServiceListSuccess(response?.getData));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get service failed";
    yield put(getServiceListFailure({ message }));
  }
}

function* onAddService({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id
      ? `/level-and-section/section/update-section/${id}`
      : `/level-and-section/section/create-section`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster("success", `Service ${id ? "Updated" : "Added"} Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} Service Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetSingleService({ payload: { id } }) {
  try {
    const url = `/service/${id}`;
    const response = yield call(get, url);
    // console.log(`response`, response);
    if (response) {
      yield put(getSingleServiceSuccess(response?.service));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single service failed";
    toaster("error", message);
    yield put(getSingleServiceFailure({ message }));
  }
}

function* onDeleteService({ payload: { id, successCallBack } }) {
  try {
    const url = `/service/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteServiceSuccess(response));
      toaster("success", "Service Deleted successfully");
      successCallBack();
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Delete service failed";
    toaster("error", message);
    yield put(deleteServiceFailure({ message }));
  }
}

export function* serviceSaga() {
  yield takeEvery(getServiceList.type, onGetService);
  yield takeEvery(addServiceData.type, onAddService);
  yield takeEvery(getSingleService.type, onGetSingleService);
  yield takeEvery(deleteService.type, onDeleteService);
}
