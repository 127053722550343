import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  addItemData,
  deleteItem,
  deleteItemFailure,
  deleteItemSuccess,
  getItemList,
  getItemListFailure,
  getItemListSuccess,
  getSingleItem,
  getSingleItemFailure,
  getSingleItemSuccess,
} from "./ItemSlice";

function* onGetItem({ payload: { page = 0, limit = 0 } }) {
  try {
    const url = `/item/data/?limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getItemListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get Item Failed";
    yield put(getItemListFailure({ message }));
  }
}

function* onAddItem({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id ? `/item/${id}` : `/item/`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster("success", `Item ${id ? "Updated" : "Added"} Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || `${id ? "Update" : "Add"} Item Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetSingleItem({ payload: { id } }) {
  try {
    const url = `/item/${id}`;
    const response = yield call(get, url);
    // console.log(`response`, response);
    if (response) {
      yield put(getSingleItemSuccess(response?.item));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get Single Item Failed";
    toaster("error", message);
    yield put(getSingleItemFailure({ message }));
  }
}

function* onDeleteItem({ payload: { id, successCallBack } }) {
  try {
    const url = `/item/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteItemSuccess(response));
      toaster("success", "Item Deleted successfully");
      successCallBack();
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Delete item failed";
    toaster("error", message);
    yield put(deleteItemFailure({ message }));
  }
}

export function* itemSaga() {
  yield takeEvery(getItemList.type, onGetItem);
  yield takeEvery(addItemData.type, onAddItem);
  yield takeEvery(getSingleItem.type, onGetSingleItem);
  yield takeEvery(deleteItem.type, onDeleteItem);
}
