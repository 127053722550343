import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  promotionList: [],
  loading: false,
  error: "",
  singleAdminPromotion: {},
  adding: false,
};

const adminPromotionSlice = createSlice({
  name: "promotion",
  initialState: initialState,
  reducers: {
    resetEmployee: (state, action) => {
      state.promotionList = [];
      state.loading = false;
      state.error = "";
      state.singleAdminPromotion = {};
      state.adding = false;
    },
    getAdminPromotionList: (state, action) => {
      state.loading = true;
    },
    getAdminPromotionListSuccess: (state, action) => {
      state.promotionList = action.payload;
      state.loading = false;
    },
    getAdminPromotionListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    getSingleAdminPromotion: (state, action) => {
      state.loading = true;
    },
    getSingleAdminPromotionSuccess: (state, action) => {
      state.singleAdminPromotion = action.payload;
      state.loading = false;
    },
    getSingleAdminPromotionFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    getCancelAdminPromotion: (state, action) => {
      state.loading = true;
    },
    getCancelAdminPromotionSuccess: (state, action) => {
      // state.singleAdminPromotion = action.payload;
      state.loading = false;
    },
    getCancelAdminPromotionFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteAdminPromotion: (state, action) => {
      state.adding = true;
    },
    deleteAdminPromotionSuccess: (state, action) => {
      state.adding = false;
    },
    deleteAdminPromotionFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getAdminPromotionList,
  getAdminPromotionListSuccess,
  getAdminPromotionListFailure,
  getSingleAdminPromotion,
  getSingleAdminPromotionSuccess,
  getSingleAdminPromotionFailure,
  getCancelAdminPromotion,
  getCancelAdminPromotionSuccess,
  getCancelAdminPromotionFailure,
  deleteAdminPromotion,
  deleteAdminPromotionSuccess,
  deleteAdminPromotionFailure,
  resetEmployee,
} = adminPromotionSlice.actions;

export default adminPromotionSlice.reducer;
