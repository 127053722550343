import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../helpers/api_helper";
import { toaster } from "../../helpers/custom/Toast";
import {
  addLeaveTypeData,
  deleteLeaveType,
  deleteLeaveTypeFailure,
  deleteLeaveTypeSuccess,
  getLeaveTypeList,
  getLeaveTypeListFailure,
  getLeaveTypeListSuccess,
  getSingleLeaveType,
  getSingleLeaveTypeFailure,
  getSingleLeaveTypeSuccess,
} from "./LeaveTypeSlice";

function* onGetLeaveType({ payload: { page = 1, limit } }) {
  try {
    const url = `/leave-type/data/?limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    // console.log("response", response);
    if (response) {
      yield put(getLeaveTypeListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get Leave Type Failed";
    yield put(getLeaveTypeListFailure({ message }));
  }
}

function* onAddLeaveType({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id ? `/leave-type/${id}` : `/leave-type`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster("success", `Leave Type ${id ? "Updated" : "Added"} Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      yield put(getLeaveTypeList({ page: 1, limit: 10 }));
      toggle();
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} Leave Type Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetSingleLeaveType({ payload: { id } }) {
  try {
    const url = `/leave-type/${id}`;
    const response = yield call(get, url);
    // console.log(`response`, response);
    if (response) {
      yield put(getSingleLeaveTypeSuccess(response?.LeaveType));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single leave type failed";
    toaster("error", message);
    yield put(getSingleLeaveTypeFailure({ message }));
  }
}

function* onDeleteLeaveType({
  payload: { id, toggle, setCurrentPage, setHandleList },
}) {
  try {
    const url = `/leave-type/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteLeaveTypeSuccess(response));
      toaster("success", "Leave Type Deleted Successfully");
      toggle(true);
      // setSelectedUser(null);
      // setCurrentPage(1);
      // setHandleList(10);
      // yield put(getLeaveTypeList({ page: 1, limit: 10 }));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Delete Leave Type failed";
    toaster("error", message);
    yield put(deleteLeaveTypeFailure({ message }));
  }
}

export function* LeaveTypeSaga() {
  yield takeEvery(getLeaveTypeList.type, onGetLeaveType);
  yield takeEvery(addLeaveTypeData.type, onAddLeaveType);
  yield takeEvery(getSingleLeaveType.type, onGetSingleLeaveType);
  yield takeEvery(deleteLeaveType.type, onDeleteLeaveType);
}
