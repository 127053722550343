import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  itemList: [],
  loading: false,
  error: "",
  singleItem: {},
  adding: false,
};

const itemSlice = createSlice({
  name: "item",
  initialState: initialState,
  reducers: {
    getItemList: (state, action) => {
      state.loading = true;
    },
    getItemListSuccess: (state, action) => {
      state.itemList = action.payload;
      state.loading = false;
    },
    getItemListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addItemData: (state, action) => {},
    getSingleItem: (state, action) => {
      state.loading = true;
    },
    getSingleItemSuccess: (state, action) => {
      state.singleItem = action.payload;
      state.loading = false;
    },
    getSingleItemFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteItem: (state, action) => {
      state.adding = true;
    },
    deleteItemSuccess: (state, action) => {
      state.adding = false;
    },
    deleteItemFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getItemList,
  getItemListSuccess,
  getItemListFailure,
  addItemData,
  getSingleItem,
  getSingleItemSuccess,
  getSingleItemFailure,
  deleteItem,
  deleteItemSuccess,
  deleteItemFailure,
} = itemSlice.actions;

export default itemSlice.reducer;
