import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../helpers/api_helper";
import { toaster } from "../../helpers/custom/Toast";

import {
  addCareerData,
  addCvScreeningData,
  addCvScreeningDataFail,
  addCvScreeningDataSuccess,
  assignAssessmentData,
  assignInterviewData,
  deleteApplicantAssessment,
  deleteApplicantAssessmentFailure,
  deleteApplicantAssessmentSuccess,
  deleteApplicantInterview,
  deleteApplicantInterviewFailure,
  deleteApplicantInterviewSuccess,
  deleteCareer,
  deleteCareerFailure,
  deleteCareerSuccess,
  getAssignedAssessment,
  getAssignedAssessmentFailure,
  getAssignedAssessmentSuccess,
  getCareerJobPost,
  getCareerJobPostFailure,
  getCareerJobPostSuccess,
  getCareerList,
  getCareerListFailure,
  getCareerListSuccess,
  getCvScreeningData,
  getSingleCareer,
  getSingleCareerFailure,
  getSingleCareerSuccess,
} from "./CareerSlice";

function* onGetCareer({
  payload: { page = 1, limit, stage, status, JobPostId, interviewCall },
}) {
  try {
    const url = `/career/data/?limit=${limit}&pageNo=${page}&stage=${stage}&status=${status}&jobPostId=${JobPostId}${
      interviewCall ? `&interviewCall=${interviewCall}` : ""
    }`;
    const response = yield call(get, url);
    // console.log("responseppp", response);
    if (response) {
      response.careers.forEach((data) => {
        data.interviewTime = "";
        data.selected = false;
      });
      yield put(getCareerListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get Career Failed";
    yield put(getCareerListFailure({ message }));
  }
}
function* onGetJobPost({ payload: { page = 1, limit } }) {
  try {
    const url = `/career/job-post/?pageNo=${page}&limit=${limit}`;
    const response = yield call(get, url);
    // console.log("responseeeet", response);
    if (response) {
      yield put(getCareerJobPostSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Career Job Post Failed";
    yield put(getCareerJobPostFailure({ message }));
  }
}

function* onGetAssignedAssessment({
  payload: { page = 0, limit, jobPostId = "", assessmentId = "" },
}) {
  try {
    const url = `career/get-applicant-who-assigned-assessment?limit=${limit}&pageNo=${page}&jobPostId=${jobPostId}&assessmentId=${assessmentId}`;
    const response = yield call(get, url);
    // console.log("responseeee", response);
    if (response) {
      yield put(getAssignedAssessmentSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get assigned assessment failed";
    yield put(getAssignedAssessmentFailure({ message }));
  }
}

function* onAddCareer({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    // console.log();
    const url = id ? `/career/${id}` : `/career`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster("success", `You ${id ? "Applied" : "Applied"} Successfully`);
      toggle();
      yield setSubmitting(false);
      yield resetForm();
      // yield put(getCareerList({ page: 1, limit: 10 }));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} Career Failed`;
    toaster("error", message);
    yield setSubmitting(false);
    toggle();
  }
}

function* onAddCvScreening({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, history, setProgress },
  },
}) {
  try {
    setProgress(0);
    const url = `/career/cv-automated-screening`;
    const response = yield call(post, url, data, {
      onUploadProgress: (data) => {
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    });
    if (response) {
      toaster("success", `CV scanned Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      toggle();
      yield put(getCvScreeningData(response));
      yield put(addCvScreeningDataSuccess());
      history && history.push("/admin/cv-vault/screening-cv-list");
    }
  } catch (error) {
    const message = error?.response?.data?.message || `CV screening Failed`;
    toaster("error", message);
    yield setSubmitting(false);
    yield put(addCvScreeningDataFail());
    setProgress(0);
    toggle();
  }
}
function* onAssignInterview({
  payload: {
    data,
    options: { setSubmitting, resetForm, history },
  },
}) {
  try {
    const url = `/career/applicant-move-to-interview`;
    const response = yield call(post, url, data);
    if (response) {
      toaster("success", `Interview Assigned Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      // yield put(getCareerList(response));
      history.push("/admin/interviewee-list");
      // history && history.push("/admin/screening-cv-list");
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || `Interview Assigned Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}
function* onAssignAssessment({
  payload: {
    data,
    options: { setSubmitting, resetForm, jobPostId, assessmentId, history },
  },
}) {
  try {
    const url = `/career/assign-assessment-to-applicant`;
    const response = yield call(post, url, data);
    if (response) {
      toaster("success", `Assessment Assigned Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      history.push("/admin/assessment-assign-list");
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || `Assessment Assigned Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetSingleCareer({ payload: { id } }) {
  try {
    const url = `/career/job-post-details/${id}`;
    const response = yield call(get, url);

    if (response) {
      yield put(getSingleCareerSuccess(response.jobPost));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single career failed";
    toaster("error", message);
    yield put(getSingleCareerFailure({ message }));
  }
}

function* onDeleteCareer({
  payload: { id, setSelectedUser, setCurrentPage, setHandleList },
}) {
  try {
    const url = `/career/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteCareerSuccess(response));
      toaster("success", "Career Deleted successfully");
      setSelectedUser(null);
      setCurrentPage(1);
      setHandleList(10);
      yield put(getCareerList({ page: 1, limit: 10 }));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Delete career failed";
    toaster("error", message);
    yield put(deleteCareerFailure({ message }));
  }
}
function* onDeleteApplicantInterview({ payload: { id, setSelectedUser } }) {
  try {
    const url = `/career/remove-applicant-from-interview/${id}`;
    const response = yield call(patch, url);
    if (response) {
      yield put(deleteApplicantInterviewSuccess(response));
      toaster("success", "Applicant Removed from Interview Successfully");
      setSelectedUser(null);
      yield put(
        getCareerList({
          page: 0,
          limit: 0,
          stage: "",
          status: "Accepted",
          JobPostId: "",
        })
      );
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Delete applicant interview failed";
    toaster("error", message);
    yield put(deleteApplicantInterviewFailure({ message }));
  }
}
function* onDeleteApplicantAssessment({
  payload: { id, jobPostId, setSelectedUser2 },
}) {
  try {
    const url = `/career/remove-applicant-from-assessment/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteApplicantAssessmentSuccess(response));
      yield put(
        getCareerList({
          page: 0,
          limit: 0,
          stage: "",
          status: "Accepted",
          jobPostId: "",
        })
      );
      toaster("success", "Applicant Removed from Assessment Successfully");
      setSelectedUser2(null);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Delete applicant assessment failed";
    toaster("error", message);
    yield put(deleteApplicantAssessmentFailure({ message }));
  }
}

export function* careerSaga() {
  yield takeEvery(getCareerList.type, onGetCareer);
  yield takeEvery(getCareerJobPost.type, onGetJobPost);
  yield takeEvery(addCareerData.type, onAddCareer);
  yield takeEvery(getSingleCareer.type, onGetSingleCareer);
  yield takeEvery(deleteCareer.type, onDeleteCareer);
  yield takeEvery(addCvScreeningData.type, onAddCvScreening);
  yield takeEvery(assignInterviewData.type, onAssignInterview);
  yield takeEvery(assignAssessmentData.type, onAssignAssessment);
  yield takeEvery(deleteApplicantInterview.type, onDeleteApplicantInterview);
  yield takeEvery(deleteApplicantAssessment.type, onDeleteApplicantAssessment);
  yield takeEvery(getAssignedAssessment.type, onGetAssignedAssessment);
}
