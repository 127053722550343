import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  assessmentList: {},
  assessmentAssignList: {},
  assessmentReviewData: {},
  loading: false,
  error: "",
  singleAssessment: {},
  adding: false,
};

const AssessmentSlice = createSlice({
  name: "employeeAssessment",
  initialState: initialState,
  reducers: {
    getAssessmentAssignList: (state, action) => {
      state.loading = true;
    },
    getAssessmentAssignListSuccess: (state, action) => {
      state.assessmentAssignList = action.payload;
      state.loading = false;
    },
    getAssessmentAssignListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    getAssessmentReview: (state, action) => {
      state.loading = true;
    },
    getAssessmentReviewSuccess: (state, action) => {
      state.assessmentReviewData = action.payload;
      state.loading = false;
    },
    getAssessmentReviewFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addAssessmentReview: (state, action) => {},
  },
});

export const {
  getAssessmentAssignList,
  getAssessmentAssignListSuccess,
  getAssessmentAssignListFailure,
  getAssessmentReview,
  getAssessmentReviewSuccess,
  getAssessmentReviewFailure,
  addAssessmentReview,
} = AssessmentSlice.actions;

export default AssessmentSlice.reducer;
