import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categoryItemList: {},
  loading: false,
  error: "",
  singleCategoryItem: {},
  adding: false,
};

const categoryItemSlice = createSlice({
  name: "categoryItem",
  initialState: initialState,
  reducers: {
    getCategoryItemList: (state, action) => {
      state.loading = true;
    },
    getCategoryItemListSuccess: (state, action) => {
      state.categoryItemList = action.payload;
      state.loading = false;
    },
    getCategoryItemListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addCategoryItemData: (state, action) => {},
    getSingleCategoryItem: (state, action) => {
      state.loading = true;
    },
    getSingleCategoryItemSuccess: (state, action) => {
      state.singleCategoryItem = action.payload;
      state.loading = false;
    },
    getSingleCategoryItemFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteCategoryItem: (state, action) => {
      state.adding = true;
    },
    deleteCategoryItemSuccess: (state, action) => {
      state.adding = false;
    },
    deleteCategoryItemFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getCategoryItemList,
  getCategoryItemListSuccess,
  getCategoryItemListFailure,
  addCategoryItemData,
  getSingleCategoryItem,
  getSingleCategoryItemSuccess,
  getSingleCategoryItemFailure,
  deleteCategoryItem,
  deleteCategoryItemSuccess,
  deleteCategoryItemFailure,
} = categoryItemSlice.actions;

export default categoryItemSlice.reducer;
