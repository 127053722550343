import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../helpers/api_helper";
import { toaster } from "../../helpers/custom/Toast";
import {
  addLeaveApplicationData,
  deleteLeaveApplication,
  deleteLeaveApplicationFailure,
  deleteLeaveApplicationSuccess,
  getLeaveApplicationList,
  getLeaveApplicationListFailure,
  getLeaveApplicationListSuccess,
  getSingleLeaveApplication,
  getSingleLeaveApplicationFailure,
  getSingleLeaveApplicationSuccess,
} from "./LeaveApplicationSlice";

function* onGetLeaveApplication({
  payload: { page = 1, limit, leaveType, employee, status, year = "" },
}) {
  try {
    const url = `/leave-application/data/?limit=${limit}&pageNo=${page}&leaveType=${leaveType}&employee=${employee}&status=${status}${
      year ? `&year=${year}` : ""
    }`;
    const response = yield call(get, url);
    // console.log("response", response);
    if (response) {
      yield put(getLeaveApplicationListSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Leave Application Failed";
    yield put(getLeaveApplicationListFailure({ message }));
  }
}

function* onAddLeaveApplication({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id ? `/leave-application/${id}` : `/leave-application`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster(
        "success",
        `Leave Application ${id ? "Updated" : "Added"} Successfully`
      );
      yield setSubmitting(false);
      yield resetForm();
      yield put(getLeaveApplicationList({ page: 1, limit: 10 }));
      toggle();
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} Leave Application Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetSingleLeaveApplication({ payload: { id } }) {
  try {
    const url = `/leave-application/${id}`;
    const response = yield call(get, url);
    // console.log(`response999`, response);
    if (response) {
      yield put(getSingleLeaveApplicationSuccess(response?.leaveType));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single leave application failed";
    toaster("error", message);
    yield put(getSingleLeaveApplicationFailure({ message }));
  }
}

function* onDeleteLeaveApplication({
  payload: { id, setSelectedUser, setCurrentPage, setHandleList },
}) {
  try {
    const url = `/leave-application/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteLeaveApplicationSuccess(response));
      toaster("success", "Leave Application Deleted Successfully");
      setSelectedUser(null);
      setCurrentPage(1);
      setHandleList(10);
      yield put(getLeaveApplicationList({ page: 1, limit: 10 }));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Delete Leave Application failed";
    toaster("error", message);
    yield put(deleteLeaveApplicationFailure({ message }));
  }
}

export function* LeaveApplicationSaga() {
  yield takeEvery(getLeaveApplicationList.type, onGetLeaveApplication);
  yield takeEvery(addLeaveApplicationData.type, onAddLeaveApplication);
  yield takeEvery(getSingleLeaveApplication.type, onGetSingleLeaveApplication);
  yield takeEvery(deleteLeaveApplication.type, onDeleteLeaveApplication);
}
