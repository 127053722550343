import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  kpiList: {},
  kpiData: [],
  loading: false,
  error: "",
  singleKpi: {},
  adding: false,
};

const KpiSlice = createSlice({
  name: "kpiData",
  initialState: initialState,
  reducers: {
    storeKpiData: (state, { payload: { name, data } }) => {
      state[name] = data;
    },
    getKpiList: (state, action) => {
      state.loading = true;
    },
    getKpiListSucces: (state, { payload }) => {
      payload.kpis?.forEach((el) => {
        el.selected = false;
        el.amount = 0;
      });
      state.kpiList = payload;
      state.kpiData = payload.kpis;
      state.loading = false;
    },
    getKpiListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addKpiData: (state, action) => {},
    getSingleKpi: (state, action) => {
      state.loading = true;
    },
    getSingleKpiSuccess: (state, action) => {
      state.singleKpi = action.payload;
      state.loading = false;
    },
    getSingleKpiFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteKpi: (state, action) => {
      state.adding = true;
    },
    deleteKpiSuccess: (state, action) => {
      state.adding = false;
    },
    deleteKpiFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getKpiList,
  getKpiListSucces,
  getKpiListFailure,
  addKpiData,
  getSingleKpi,
  getSingleKpiSuccess,
  getSingleKpiFailure,
  deleteKpi,
  deleteKpiSuccess,
  deleteKpiFailure,
  storeKpiData,
} = KpiSlice.actions;

export default KpiSlice.reducer;
