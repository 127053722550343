import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  error: "",
  singleQuestion: {},
  questionGroup: {},
  singleWritingQuestion: {},
  adding: false,
  reRender: false,
  questionNumber: 0,
  allQuestions: [],
  status: {},
  questionList: {},
  writingQuestion: [],
  aptitudeQuestion: [],
  questionLoading: false,
  singleAptitudeQuestion: {},
};

const corporateQuestionSlice = createSlice({
  name: "corporate",
  initialState: initialState,
  reducers: {
    storeCorporateQuestion: (state, { payload: { name, data } }) => {
      state[name] = data;
    },
    // question single
    getSingleCorporateQuestion: (state, action) => {
      state.loading = true;
    },
    getSingleCorporateQuestionSuccess: (
      state,
      { payload: { data, number, question } }
    ) => {
      // console.log(`data, number, question`, data, number, question);
      state.questionGroup = data || {};
      state.singleQuestion = question || {};
      state.questionNumber = number;
      state.loading = false;
    },
    getSingleCorporateQuestionFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    // all question
    getAllQuestions: (state, action) => {
      state.loading = true;
    },
    getAllQuestionsSuccess: (state, { payload: { questions, status } }) => {
      state.allQuestions = questions || [];
      state.status = status || {};
      state.loading = false;
    },
    getAllQuestionsFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    /// writing question
    getWritingQuestions: (state, action) => {
      state.loading = true;
    },
    getWritingQuestionsSuccess: (state, action) => {
      state.writingQuestion = action.payload;
      state.loading = false;
    },
    getWritingQuestionsFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    /// aptitude question
    getAptitudeQuestions: (state, action) => {
      state.loading = true;
    },
    getAptitudeQuestionsSuccess: (state, action) => {
      state.aptitudeQuestion = action.payload;
      state.writingQuestion = action.payload;
      state.loading = false;
    },
    getAptitudeQuestionsFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    /// add question
    addCorporateQuestions: (state, action) => {},
    addQuestionsSuccess: (state, action) => {
      state.reRender = !state.reRender;
    },
    /// add writing
    addWritingQuestion: (state, action) => {},
    /// add aptitude
    addAptitudeQuestion: (state, action) => {},
    /// update question status
    updateQuestionStatus: (state, action) => {
      state.adding = true;
    },
    updateQuestionStatusSuccess: (state, action) => {
      state.adding = false;
    },
    updateQuestionStatusFailure: (state, action) => {
      state.adding = false;
    },
    //. single writing
    getSingleWritingQuestion: (state, action) => {
      state.loading = true;
    },
    getSingleWritingQuestionSuccess: (state, { payload }) => {
      state.singleWritingQuestion = payload;
      state.loading = false;
    },
    getSingleWritingQuestionFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    //. single writing
    getSingleAptitudeQuestion: (state, action) => {
      state.loading = true;
    },
    getSingleAptitudeQuestionSuccess: (state, { payload }) => {
      state.singleAptitudeQuestion = payload;
      state.loading = false;
    },
    getSingleAptitudeQuestionFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    removeQuestion: (state, action) => {
      state.adding = true;
    },
    removeQuestionSuccess: (state, action) => {
      state.adding = false;
    },
    removeQuestionFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getAllQuestions,
  getAllQuestionsSuccess,
  getAllQuestionsFailure,
  getWritingQuestions,
  getWritingQuestionsSuccess,
  getWritingQuestionsFailure,
  getAptitudeQuestions,
  getAptitudeQuestionsSuccess,
  getAptitudeQuestionsFailure,
  getSingleAptitudeQuestion,
  getSingleAptitudeQuestionSuccess,
  getSingleAptitudeQuestionFailure,
  getSingleWritingQuestion,
  getSingleWritingQuestionSuccess,
  getSingleWritingQuestionFailure,
  addCorporateQuestions,
  addQuestionsSuccess,
  getSingleCorporateQuestion,
  getSingleCorporateQuestionSuccess,
  getSingleCorporateQuestionFailure,
  updateQuestionStatus,
  updateQuestionStatusSuccess,
  updateQuestionStatusFailure,
  removeQuestion,
  removeQuestionSuccess,
  removeQuestionFailure,
  addWritingQuestion,
  addAptitudeQuestion,
  storeCorporateQuestion,
} = corporateQuestionSlice.actions;

export default corporateQuestionSlice.reducer;
