import { call, put, takeEvery } from "redux-saga/effects";
import { get, patch, post } from "../../helpers/api_helper";
import { toaster } from "../../helpers/custom/Toast";
import {
  addUserData,
  getSearchUser,
  getSearchUserFailure,
  getSearchUserSuccess,
  getSingleUser,
  getSingleUserFailure,
  getSingleUserSuccess,
  getUserList,
  getUserListFailure,
  getUserListSuccess,
} from "./UserSlice";

function* onGetUser({ payload: { page = 1, limit, role, organization } }) {
  try {
    const url = `/users/data?userRole=${role}&organization=${organization}&limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getUserListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get Users Failed";
    yield put(getUserListFailure({ message }));
  }
}

function* onAddUserUser({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, history, id },
  },
}) {
  try {
    const url = id ? `/users/${id}` : `/users`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster("success", `User ${id ? "updated" : "added"} successfully`);
      yield setSubmitting(false);
      yield resetForm();
      yield put(
        getUserList({ page: 1, limit: 10, role: "", organization: "" })
      );
      toggle();
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || `${id ? "Update" : "Add"} Users Failed`;
    toaster("error", message);
    yield setSubmitting(false);
    yield resetForm();
  }
}

function* onGetSingleUserUser({ payload: { id } }) {
  try {
    const url = `/users/${id}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getSingleUserSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get single user failed";
    toaster("error", message);
    yield put(getSingleUserFailure({ message }));
  }
}
function* onGetSearchUser({ payload: { searchVal } }) {
  try {
    const url = `/users/user-search?searchKeyword=${searchVal}`;
    const response = yield call(get, url);
    // console.log(`responsesearch`, response);
    if (response) {
      yield put(getSearchUserSuccess(response?.users));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get search user failed";
    toaster("error", message);
    yield put(getSearchUserFailure({ message }));
  }
}

// function* onDeleteUserUser({
//   payload: { id, setSelectedUser, setCurrentPage, setHandleList },
// }) {
//   try {
//     const url = `/users/${id}`;
//     const response = yield call(del, url);
//     if (response) {
//       yield put(deleteUserSuccess(response));
//       toaster("success", "User delete successfully");
//       setSelectedUser(null);
//       setCurrentPage(1);
//       setHandleList(10);
//       yield put(getUserList({ page: 1, limit: 10 }));
//     }
//   } catch (error) {
//     const message = error?.response?.data?.message || "Delete user failed";
//     toaster("error", message);
//     yield put(deleteUserFailure({ message }));
//   }
// }

export function* userSaga() {
  yield takeEvery(getUserList.type, onGetUser);
  yield takeEvery(addUserData.type, onAddUserUser);
  yield takeEvery(getSingleUser.type, onGetSingleUserUser);
  yield takeEvery(getSearchUser.type, onGetSearchUser);
}
