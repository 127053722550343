import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  addCategoryItemData,
  deleteCategoryItem,
  deleteCategoryItemFailure,
  deleteCategoryItemSuccess,
  getCategoryItemList,
  getCategoryItemListFailure,
  getCategoryItemListSuccess,
  getSingleCategoryItem,
  getSingleCategoryItemFailure,
  getSingleCategoryItemSuccess,
} from "./CategoryItemSlice";

function* onGetCategoryItem({ payload: { page = 0, limit = 0 } }) {
  try {
    const url = `/item-category/data?limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getCategoryItemListSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Category Item failed";
    yield put(getCategoryItemListFailure({ message }));
  }
}

function* onAddCategoryItem({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id ? `/item-category/${id}` : `/item-category`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster(
        "success",
        `CategoryItem ${id ? "Updated" : "Added"} Successfully`
      );
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} Category Item Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetSingleCategoryItem({ payload: { id } }) {
  try {
    const url = `/item-category/${id}`;
    const response = yield call(get, url);
    // console.log(`response`, response);
    if (response) {
      yield put(getSingleCategoryItemSuccess(response?.categoryItem));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single category Item failed";
    toaster("error", message);
    yield put(getSingleCategoryItemFailure({ message }));
  }
}

function* onDeleteCategoryItem({ payload: { id, successCallBack } }) {
  try {
    const url = `/item-category/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteCategoryItemSuccess(response));
      toaster("success", "Category Item Deleted successfully");
      successCallBack();
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Delete Category Item failed";
    toaster("error", message);
    yield put(deleteCategoryItemFailure({ message }));
  }
}

export function* categoryItemSaga() {
  yield takeEvery(getCategoryItemList.type, onGetCategoryItem);
  yield takeEvery(addCategoryItemData.type, onAddCategoryItem);
  yield takeEvery(getSingleCategoryItem.type, onGetSingleCategoryItem);
  yield takeEvery(deleteCategoryItem.type, onDeleteCategoryItem);
}
