import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  addPerformanceData,
  deletePerformance,
  deletePerformanceFailure,
  deletePerformanceSuccess,
  getPerformanceList,
  getPerformanceListFailure,
  getPerformanceListSuccess,
  getPerformanceRattingList,
  getPerformanceRattingListFailure,
  getPerformanceRattingListSuccess,
  getSinglePerformance,
  getSinglePerformanceFailure,
  getSinglePerformanceSuccess,
} from "./performanceSlice";

function* onGetPerformance({ payload: { page = 0, limit = 0 } }) {
  try {
    const url = `/performance-grade/data/?limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getPerformanceListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get Performance Failed";
    yield put(getPerformanceListFailure({ message }));
  }
}

function* onGetPerformanceRatting({
  payload: {
    page = 0,
    limit = 0,
    year = "",
    performanceGradeId = "",
    sbu = "",
    department = "",
    designation = "",
    employeeId = "",
  },
}) {
  try {
    const url = `/performance-rating/data/?limit=${limit}&pageNo=${page}&year=${year}&performanceGradeId=${performanceGradeId}&concernCompany=${sbu}&department=${department}&designation=${designation}&employeeId=${employeeId}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getPerformanceRattingListSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Performance Ratting Failed";
    yield put(getPerformanceRattingListFailure({ message }));
  }
}

function* onAddPerformance({
  payload: {
    data,
    options: { setSubmitting, resetForm, id = "", history },
  },
}) {
  try {
    const url = id ? `/performance-grade/${id}` : `/performance-grade/`;
    const response = yield call(id ? patch : post, url, data);

    if (response) {
      toaster(
        "success",
        `Performance ${id ? "Updated" : "Added"} Successfully`
      );
      yield setSubmitting(false);
      yield resetForm();
      history.push("/admin/performance-grade-chart");
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} Performance Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetSinglePerformance({ payload: { id = "" } }) {
  try {
    const url = `/performance-grade/${id}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getSinglePerformanceSuccess(response?.performanceGrade));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Single Performance Failed";
    toaster("error", message);
    yield put(getSinglePerformanceFailure({ message }));
  }
}

function* onDeletePerformance({ payload: { id = "", successCallBack } }) {
  try {
    const url = `/performance-grade/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deletePerformanceSuccess(response));
      toaster("success", "Performance Deleted successfully");
      successCallBack();
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Delete performance failed";
    toaster("error", message);
    yield put(deletePerformanceFailure({ message }));
  }
}

export function* performanceSaga() {
  yield takeEvery(getPerformanceList.type, onGetPerformance);
  yield takeEvery(getPerformanceRattingList.type, onGetPerformanceRatting);
  yield takeEvery(addPerformanceData.type, onAddPerformance);
  yield takeEvery(getSinglePerformance.type, onGetSinglePerformance);
  yield takeEvery(deletePerformance.type, onDeletePerformance);
}
