import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  salaryList: [],
  salarySheet: [],
  salarySheetData: {},
  loading: false,
  error: "",
  singleSalary: {},
  bankSheet: {},
  adding: false,
};

const salarySlice = createSlice({
  name: "salary",
  initialState: initialState,
  reducers: {
    // resetEmployee: (state, action) => {
    //   state.salaryList = [];
    //   state.loading = false;
    //   state.error = "";
    //   state.singleSalary = {};
    //   state.adding = false;
    // },
    storeSalary: (state, { payload: { name, data } }) => {
      state[name] = data;
    },
    getSalaryList: (state, action) => {
      state.loading = true;
    },
    getSalaryListSuccess: (state, action) => {
      state.salaryList = action.payload?.employees;
      state.loading = false;
    },
    getSalaryListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addSalaryData: (state, action) => {},

    getSalarySheet: (state, action) => {
      state.loading = true;
    },
    getSalarySheetSuccess: (state, action) => {
      state.salarySheetData = action.payload;
      state.salarySheet = action.payload?.salarySheets;
      state.loading = false;
    },
    getSalarySheetFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },

    addPaidSalary: (state, action) => {},

    getSingleSalary: (state, action) => {
      state.loading = true;
    },
    getSingleSalarySuccess: (state, action) => {
      state.singleSalary = action.payload;
      state.loading = false;
    },
    getSingleSalaryFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    getBankSheet: (state, action) => {
      state.loading = true;
    },
    getBankSheetSuccess: (state, action) => {
      state.bankSheet = action.payload;
      state.loading = false;
    },
    getBankSheetFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteSalary: (state, action) => {
      state.adding = true;
    },
    deleteSalarySuccess: (state, action) => {
      state.adding = false;
    },
    deleteSalaryFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  storeSalary,
  getSalaryList,
  getSalaryListSuccess,
  getSalaryListFailure,
  addSalaryData,
  getSalarySheet,
  addPaidSalary,
  getSalarySheetSuccess,
  getSalarySheetFailure,
  getSingleSalary,
  getSingleSalarySuccess,
  getSingleSalaryFailure,
  getBankSheet,
  getBankSheetSuccess,
  getBankSheetFailure,
  deleteSalary,
  deleteSalarySuccess,
  deleteSalaryFailure,
} = salarySlice.actions;

export default salarySlice.reducer;
