import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../helpers/api_helper";
import { toaster } from "../../helpers/custom/Toast";
import {
  addPayrollData,
  deletePayroll,
  deletePayrollFailure,
  deletePayrollSuccess,
  getPayrollList,
  getPayrollListFailure,
  getPayrollListSuccess,
  getSinglePayroll,
  getSinglePayrollFailure,
  getSinglePayrollSuccess,
} from "./payrollSlice";

function* onGetPayroll({
  payload: {
    page = 0,
    limit = 0,
    concernCompany = "",
    department = "",
    designation = "",
    salaryType = "",
    month = "",
    fromDate = "",
    toDate = "",
  },
}) {
  try {
    const url = `/salary/data/?limit=${limit}&pageNo=${page}&concernCompany=${concernCompany}&department=${department}&designation=${designation}&salaryType=${salaryType}&month=${month}&fromDate=${fromDate}&toDate=${toDate}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getPayrollListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get payroll failed";
    yield put(getPayrollListFailure({ message }));
  }
}

function* onAddPayroll({
  payload: {
    data,
    options: { setSubmitting, resetForm, id },
  },
}) {
  try {
    const url = id ? `/salary/${id}` : `/salary`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster("success", `Payroll ${id ? "Updated" : "Added"} Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      // toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} Payroll Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetSinglePayroll({ payload: { id } }) {
  try {
    const url = `/employee/${id}`;
    const response = yield call(get, url);
    // console.log(`response`, response);
    if (response) {
      yield put(getSinglePayrollSuccess(response?.payroll));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single payroll failed";
    toaster("error", message);
    yield put(getSinglePayrollFailure({ message }));
  }
}

function* onDeletePayroll({ payload: { id, successCallBack } }) {
  try {
    const url = `/employee/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deletePayrollSuccess(response));
      toaster("success", "Payroll Deleted successfully");
      successCallBack();
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Delete payroll failed";
    toaster("error", message);
    yield put(deletePayrollFailure({ message }));
  }
}

export function* payrollSaga() {
  yield takeEvery(getPayrollList.type, onGetPayroll);
  yield takeEvery(addPayrollData.type, onAddPayroll);
  yield takeEvery(getSinglePayroll.type, onGetSinglePayroll);
  yield takeEvery(deletePayroll.type, onDeletePayroll);
}
