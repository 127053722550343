import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  incrementList: {},
  loading: false,
  error: "",
  singleIncrement: {},
  incrementById: {},
  prevSalary: {},
  adding: false,
  prevSalaryLoading: false,
};

const adminIncrementSlice = createSlice({
  name: "adminIncrement",
  initialState: initialState,
  reducers: {
    resetIncrement: (state, action) => {
      state.incrementList = [];
      state.loading = false;
      state.error = "";
      state.singleIncrement = {};
      state.adding = false;
    },
    getIncrementList: (state, action) => {
      state.loading = true;
    },
    getIncrementListSuccess: (state, action) => {
      state.incrementList = action.payload;
      state.loading = false;
    },
    getIncrementListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addIncrementData: (state, action) => {},
    getSingleIncrement: (state, action) => {
      state.loading = true;
    },
    getSingleIncrementSuccess: (state, action) => {
      state.singleIncrement = action.payload;
      state.loading = false;
    },
    getSingleIncrementFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    getIncrementById: (state, action) => {
      state.loading = true;
    },
    getIncrementByIdSuccess: (state, action) => {
      state.incrementById = action.payload;
      state.loading = false;
    },
    getIncrementByIdFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    getPrevSalary: (state, action) => {
      state.prevSalaryLoading = true;
    },
    getPrevSalarySuccess: (state, action) => {
      state.prevSalary = action.payload;
      state.prevSalaryLoading = false;
    },
    getPrevSalaryFailure: (state, action) => {
      state.error = action.payload.message;
      state.prevSalaryLoading = false;
    },
    getCancelSalary: (state, action) => {
      state.loading = true;
    },
    getCancelSalarySuccess: (state, action) => {
      state.prevSalary = action.payload;
      state.loading = false;
    },
    getCancelSalaryFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteIncrement: (state, action) => {
      state.adding = true;
    },
    deleteIncrementSuccess: (state, action) => {
      state.adding = false;
    },
    deleteIncrementFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getIncrementList,
  getIncrementListSuccess,
  getIncrementListFailure,
  addIncrementData,
  getSingleIncrement,
  getSingleIncrementSuccess,
  getSingleIncrementFailure,
  getIncrementById,
  getIncrementByIdSuccess,
  getIncrementByIdFailure,
  getPrevSalary,
  getPrevSalarySuccess,
  getPrevSalaryFailure,
  getCancelSalary,
  getCancelSalarySuccess,
  getCancelSalaryFailure,
  deleteIncrement,
  deleteIncrementSuccess,
  deleteIncrementFailure,
  resetIncrement,
} = adminIncrementSlice.actions;

export default adminIncrementSlice.reducer;
