import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  taxList: {},
  loading: false,
  error: "",
  singleTax: {},
  adding: false,
  taxReport: {},
  taxReportDetails: {},
};

const taxSlice = createSlice({
  name: "tax",
  initialState: initialState,
  reducers: {
    getTaxList: (state, action) => {
      state.loading = true;
    },
    getTaxListSuccess: (state, action) => {
      state.taxList = action.payload;
      state.loading = false;
    },
    getTaxListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addTaxData: (state, action) => {},
    getSingleTax: (state, action) => {
      state.loading = true;
    },
    getSingleTaxSuccess: (state, action) => {
      state.singleTax = action.payload;
      state.loading = false;
    },
    getSingleTaxFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteTax: (state, action) => {
      state.adding = true;
    },
    deleteTaxSuccess: (state, action) => {
      state.adding = false;
    },
    deleteTaxFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
    getTaxReport: (state, action) => {
      state.loading = true;
    },
    getTaxReportSuccess: (state, action) => {
      state.taxReport = action.payload;
      state.loading = false;
    },
    getTaxReportFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    getSingleTaxReport: (state, action) => {
      state.loading = true;
    },
    getSingleTaxReportSuccess: (state, action) => {
      state.taxReportDetails = action.payload;
      state.loading = false;
    },
    getSingleTaxReportFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
  },
});

export const {
  getTaxList,
  getTaxListSuccess,
  getTaxListFailure,
  addTaxData,
  getSingleTax,
  getSingleTaxSuccess,
  getSingleTaxFailure,
  deleteTax,
  deleteTaxSuccess,
  deleteTaxFailure,
  getTaxReport,
  getTaxReportSuccess,
  getTaxReportFailure,
  getSingleTaxReport,
  getSingleTaxReportSuccess,
  getSingleTaxReportFailure,
} = taxSlice.actions;

export default taxSlice.reducer;
