import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  addHolidaysData,
  deleteHolidays,
  deleteHolidaysFailure,
  deleteHolidaysSuccess,
  getHolidaysList,
  getHolidaysListFailure,
  getHolidaysListSuccess,
  getMonthlyHolidays,
  getMonthlyHolidaysFailure,
  getMonthlyHolidaysSuccess,
  getSingleHolidays,
  getSingleHolidaysFailure,
  getSingleHolidaysSuccess,
} from "./HolidaysSlice";

function* onGetHolidays({ payload: { page = 0, limit = 0, type = "" } }) {
  try {
    const url = `/holidays/data/?limit=${limit}&pageNo=${page}&type=${type}`;
    const response = yield call(get, url);
    // console.log("response222", response);
    if (response) {
      yield put(getHolidaysListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get Holiday Failed";
    yield put(getHolidaysListFailure({ message }));
  }
}

function* onAddHolidays({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id ? `/holidays/${id}` : `/holidays`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster("success", `Holiday ${id ? "Updated" : "Added"} Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      yield put(getHolidaysList({ page: 1, limit: 10 }));
      toggle();
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} Holiday Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetSingleHolidays({ payload: { id } }) {
  try {
    const url = `/holidays/${id}`;
    const response = yield call(get, url);
    // console.log(`response`, response);
    if (response) {
      yield put(getSingleHolidaysSuccess(response?.Holidays));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single holiday failed";
    toaster("error", message);
    yield put(getSingleHolidaysFailure({ message }));
  }
}

function* onDeleteHolidays({
  payload: { id, setSelectedUser, setCurrentPage, setHandleList },
}) {
  try {
    const url = `/holidays/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteHolidaysSuccess(response));
      toaster("success", "Holiday Deleted Successfully");
      setSelectedUser(null);
      setCurrentPage(1);
      setHandleList(10);
      yield put(getHolidaysList({ page: 1, limit: 10 }));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Delete Holiday failed";
    toaster("error", message);
    yield put(deleteHolidaysFailure({ message }));
  }
}

function* onGetMonthlyHolidays({ payload: { month = "", employeeId = "" } }) {
  try {
    const url = `/holidays/${
      employeeId
        ? "get-holidays-calendar-by-employee"
        : "get-holidays-for-calendar"
    }?month=${month}&startDate=${month}-01&endDate=${month}-31&employeeId=${employeeId}`;
    const response = yield call(get, url);
    // console.log("response222", response);
    if (response) {
      yield put(getMonthlyHolidaysSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get monthly holiday failed";
    yield put(getMonthlyHolidaysFailure({ message }));
  }
}

export function* HolidaysSaga() {
  yield takeEvery(getHolidaysList.type, onGetHolidays);
  yield takeEvery(addHolidaysData.type, onAddHolidays);
  yield takeEvery(getSingleHolidays.type, onGetSingleHolidays);
  yield takeEvery(deleteHolidays.type, onDeleteHolidays);
  // calendar
  yield takeEvery(getMonthlyHolidays.type, onGetMonthlyHolidays);
}
