import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  applicantassessmentList: {},
  applicantprofileData: {},
  assessmentViewData: {},
  appliedPositionList: {},
  singleOfferLetterData: {},
  appliedPositionData: {},
  offerLetter: {},
  loading: false,
  error: "",
  adding: false,
  chartLoading: true,
  chartData: {},
  assessmentData: [],
  labels: [],
  colors: [],
  types: [],
  time: 0,
};

const applicantSlice = createSlice({
  name: "applicant",
  initialState: initialState,
  reducers: {
    getApplicantAssessmentList: (state, action) => {
      state.loading = true;
    },
    getApplicantAssessmentListSuccess: (state, action) => {
      state.applicantassessmentList = action.payload;
      state.loading = false;
    },
    getApplicantAssessmentListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    getApplicantProfile: (state, action) => {
      state.loading = true;
    },
    getApplicantProfileSuccess: (state, action) => {
      state.applicantprofileData = action.payload;
      state.loading = false;
    },
    getApplicantProfileFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    getApplicantChart: (state, action) => {
      state.chartLoading = true;
    },

    getApplicantChartSuccess: (state, { payload }) => {
      let assessment = [];
      let colors = [];
      let labels = [];
      let types = [];
      // #02BC89
      // #EB2027
      // #F09959
      payload.assessments?.forEach((el, idx) => {
        assessment.push(1);
        types.push(el?.assessmentId?.type);
        labels.push(el?.assessmentId?.name);
        colors.push(
          el?.result === "Pending"
            ? "#F09959"
            : el?.result === "Failed"
            ? "#EB2027"
            : "#02BC89"
        );
      });
      state.assessmentData = assessment;
      state.labels = labels;
      state.colors = colors;
      state.types = types;
      state.chartData = payload;
      state.chartLoading = false;
    },
    getApplicantChartFailure: (state, action) => {
      state.error = action.payload.message;
      state.chartLoading = false;
    },
    getAssessmentView: (state, action) => {
      state.loading = true;
    },
    getAssessmentViewSuccess: (state, action) => {
      state.assessmentViewData = action.payload;
      state.time = action.payload?.assessment?.assessmentId?.duration;
      state.loading = false;
    },
    getAssessmentViewFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addAssessmentExamData: (state, action) => {},
    getAppliedPositionList: (state, action) => {
      state.loading = true;
    },
    getAppliedPositionListSuccess: (state, action) => {
      state.appliedPositionList = action.payload;
      state.loading = false;
    },
    getAppliedPositionListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    getOfferLetter: (state, action) => {
      state.loading = true;
    },
    getOfferLetterSuccess: (state, action) => {
      state.offerLetter = action.payload;
      state.loading = false;
    },
    getOfferLetterFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    getSingleOfferLetter: (state, action) => {
      state.loading = true;
    },
    getSingleOfferLetterSuccess: (state, action) => {
      state.singleOfferLetterData = action.payload;
      state.loading = false;
    },
    getSingleOfferLetterFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    getAppliedPosition: (state, action) => {
      state.loading = true;
    },
    getAppliedPositionSuccess: (state, action) => {
      state.appliedPositionData = action.payload;
      state.loading = false;
    },
    getAppliedPositionFailed: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addOfferLetterData: (state, action) => {},
  },
});

export const {
  getAppliedPosition,
  getAppliedPositionSuccess,
  getAppliedPositionFailed,
  getSingleOfferLetter,
  getSingleOfferLetterSuccess,
  getSingleOfferLetterFailure,
  addOfferLetterData,
  getOfferLetter,
  getOfferLetterSuccess,
  getOfferLetterFailure,
  getApplicantAssessmentList,
  getAppliedPositionList,
  getAppliedPositionListSuccess,
  getAppliedPositionListFailure,
  getApplicantAssessmentListSuccess,
  getApplicantAssessmentListFailure,
  getApplicantProfile,
  getApplicantProfileSuccess,
  getApplicantProfileFailure,
  getApplicantChart,
  getApplicantChartSuccess,
  getApplicantChartFailure,
  getAssessmentView,
  getAssessmentViewSuccess,
  getAssessmentViewFailure,
  addAssessmentExamData,
} = applicantSlice.actions;

export default applicantSlice.reducer;
