import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  payrollList: [],
  loading: false,
  error: "",
  singlePayroll: {},
  adding: false,
};

const payrollSlice = createSlice({
  name: "payroll",
  initialState: initialState,
  reducers: {
    // resetEmployee: (state, action) => {
    //   state.payrollList = [];
    //   state.loading = false;
    //   state.error = "";
    //   state.singlePayroll = {};
    //   state.adding = false;
    // },
    getPayrollList: (state, action) => {
      state.loading = true;
    },
    getPayrollListSuccess: (state, action) => {
      state.payrollList = action.payload;
      state.loading = false;
    },
    getPayrollListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addPayrollData: (state, action) => {},
    getSinglePayroll: (state, action) => {
      state.loading = true;
    },
    getSinglePayrollSuccess: (state, action) => {
      state.singlePayroll = action.payload;
      state.loading = false;
    },
    getSinglePayrollFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },

    deletePayroll: (state, action) => {
      state.adding = true;
    },
    deletePayrollSuccess: (state, action) => {
      state.adding = false;
    },
    deletePayrollFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getPayrollList,
  getPayrollListSuccess,
  getPayrollListFailure,
  addPayrollData,
  getSinglePayroll,
  getSinglePayrollSuccess,
  getSinglePayrollFailure,
  deletePayroll,
  deletePayrollSuccess,
  deletePayrollFailure,
} = payrollSlice.actions;

export default payrollSlice.reducer;
